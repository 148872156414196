import React, { useState, useEffect, useCallback } from "react";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import ProjectCard from "../../../components/common/cards/ProjectCard";
import { useNavigate } from "react-router-dom";
import { useProjects } from "../../../hooks/useProjects/useProjects";
import ErrorMessage from "../../../components/common/empty/ErrorMessage";
import LoadingPage from "../../../components/common/empty/LoadingPage";
import EmptyPage from "../../../components/common/empty/EmptyPage";

const Projects = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const perPage = 20;

  const { data, isLoading, isError } = useProjects(currentPage, perPage);

  // Update projects and pagination state when new data arrives
  useEffect(() => {
    if (data) {
      setProjects((prevProjects) => {
        const projectIds = new Set(prevProjects.map((project) => project.id));
        const newProjects = data.projects.filter(
          (project) => !projectIds.has(project.id)
        );
        return currentPage === 1
          ? data.projects
          : [...prevProjects, ...newProjects];
      });

      // Calculate if there are more items to load
      setHasMore(data.pagination.total_items > currentPage * perPage);
    }
  }, [data, currentPage]);

  const handleAddClient = () => {
    navigate("/projects/create");
  };

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      if (!isLoading && hasMore) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  }, [isLoading, hasMore]);

  useEffect(() => {
    if (hasMore) {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll, hasMore]);

  return (
    <div>
      <PageHeadings
        title="Projects"
        tags={[]}
        showCta={true}
        ctaLabel="Create Project"
        handleCtaAction={handleAddClient}
      />
      {isLoading && currentPage === 1 ? (
        <LoadingPage />
      ) : isError ? (
        <ErrorMessage />
      ) : projects.length === 0 ? (
        <EmptyPage
          title={"Get Started with Your First Project"}
          description={
            "It looks like you haven’t created any projects yet. Start by setting up your first project to organize your work, collaborate with your team, and track progress effectively."
          }
        />
      ) : (
        <>
          <div className="mb-4 grid gap-4 sm:grid-cols-2 md:mb-8 lg:grid-cols-3">
            {projects?.map((project) => (
              <ProjectCard key={project?.id} data={project} />
            ))}
          </div>
          {!hasMore && !isLoading && currentPage > 1 && (
            <div className="text-center py-4">No more projects to load.</div>
          )}
        </>
      )}
    </div>
  );
};

export default Projects;

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Tabs = ({ tabs }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentTab =
    new URLSearchParams(location.search).get("tab") || tabs[0].label;

  const handleTabClick = (tabLabel) => {
    navigate(`?tab=${tabLabel}`);
  };

  return (
    <div>
      <div className="flex border-b">
        {tabs.map((tab) => (
          <button
            key={tab.label}
            className={`py-2 px-4 -mb-px text-sm font-medium ${
              currentTab === tab.label
                ? "border-b-2 border-blue-500 text-blue-600"
                : "border-b-2 border-transparent text-gray-500 hover:text-gray-700"
            }`}
            onClick={() => handleTabClick(tab.label)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="pt-4">
        {tabs.map(
          (tab) =>
            currentTab === tab.label && <div key={tab.label}>{tab.content}</div>
        )}
      </div>
    </div>
  );
};

export default Tabs;

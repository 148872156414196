import React, { useState } from "react";
import CardHeadings from "../../../../components/common/pageHeadings/CardHeadings";
import TableContainer from "../../../../components/common/tables/TableContainer";
import SlideOver from "../../../../components/layout/SlideOver";
import { useFilesByProject } from "../../../../hooks/useFiles/useFiles";
import { useNavigate, useParams } from "react-router-dom";
import FileDetails from "../../files/FileDetails";

const ProjectFiles = () => {
  const { id: projectId } = useParams();
  const navigate = useNavigate();
  const [sideOverOpen, setSideOverOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const { data: files, isLoading, isError } = useFilesByProject(projectId);

  const handleRowClick = (data) => {
    setSideOverOpen((prevState) => !prevState);
    setSelectedRow(data || {});
  };

  const handleAddFile = () => {
    navigate(`/projects/${projectId}/create-file`);
  };

  return (
    <div className="bg-white rounded-lg pb-8">
      <CardHeadings
        title={"Files"}
        showCta={true}
        ctaLabel={"Add File"}
        handleCtaAction={handleAddFile}
      />
      <TableContainer
        hasCheckbox={false}
        columns={[]}
        columnType={"files"}
        rowType={"files"}
        loading={isLoading}
        error={isError}
        data={files}
        handleRowClick={handleRowClick}
      />
      <SlideOver
        open={sideOverOpen}
        setOpen={setSideOverOpen}
        title="File Details"
      >
        <FileDetails
          data={selectedRow}
          closeSidebar={() => setSideOverOpen(false)}
        />
      </SlideOver>
    </div>
  );
};

export default ProjectFiles;

import React, { useState, useEffect, useRef } from "react";
import { getInitials } from "../../../../utils/helpers";

const TaskModal = ({ task, onClose }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  if (!task) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
      <div
        ref={modalRef}
        className="bg-gray-50 shadow-lg w-full max-w-5xl h-full md:h-auto overflow-hidden md:rounded-md"
      >
        <div className="bg-white flex flex-col md:flex-row h-full overflow-y-auto">
          {/* Left section */}
          <div className="flex-1 p-4">
            <TaskTitle
              title={task.title}
              projectName={task.project_name}
              onClose={onClose}
            />
            <TaskDescription description={task.description} />
            <TaskSubtasks subtasks={task.subtasks} />
            <TaskComments comments={task.comments} />
          </div>

          {/* Right section (Sidebar) */}
          <div className="w-full md:max-w-xs bg-gray-50 p-4 border-t md:border-t-0 md:border-l">
            <TaskSidebar task={task} onClose={onClose} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskModal;

const TaskTitle = ({ title, projectName, onClose }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(title);

  const handleTitleEdit = () => {
    setIsEditing(true);
  };

  const handleSaveTitle = () => {
    setIsEditing(false);
    // Save the new title to the backend or state
  };

  return (
    <div>
      <div>
        <div className="flex items-center justify-between w-full">
          {/* Left Section: Project Name */}
          <div className="text-sm text-gray-500">
            <span>{projectName}</span>
          </div>

          {/* Right Section: Close Button */}
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 flex md:hidden"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        {isEditing ? (
          <input
            type="text"
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
            onBlur={handleSaveTitle}
            className="text-2xl font-semibold border-b-2 focus:outline-none"
            autoFocus
          />
        ) : (
          <h2 className="text-2xl font-semibold mb-2" onClick={handleTitleEdit}>
            {newTitle}
          </h2>
        )}
      </div>
    </div>
  );
};

const TaskDescription = ({ description }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newDescription, setNewDescription] = useState(description);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
    // Save new description
  };

  const handleCancel = () => {
    setNewDescription(description);
    setIsEditing(false);
  };

  return (
    <div className="my-4">
      <h3 className="text-lg font-semibold mb-2">Description</h3>
      {isEditing ? (
        <div>
          <textarea
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
            className="w-full border p-2 rounded-md"
          />
          <div className="mt-2">
            <button
              onClick={handleSave}
              className="bg-blue-500 text-white px-3 py-1 rounded"
            >
              Save
            </button>
            <button onClick={handleCancel} className="ml-2 text-gray-500">
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <p className="text-gray-700" onClick={handleEdit}>
          {newDescription || "No description available."}
        </p>
      )}
    </div>
  );
};

const TaskSubtasks = ({ subtasks = [] }) => {
  const [subtaskList, setSubtaskList] = useState(subtasks);
  const [editingSubtaskIndex, setEditingSubtaskIndex] = useState(null);
  const [newSubtask, setNewSubtask] = useState("");
  const [editedSubtaskTitle, setEditedSubtaskTitle] = useState("");

  // Toggle subtask completion
  const handleToggleSubtask = (index) => {
    const updatedSubtasks = [...subtaskList];
    updatedSubtasks[index].completed = !updatedSubtasks[index].completed;
    setSubtaskList(updatedSubtasks);
  };

  // Handle start of editing
  const handleEditSubtask = (index) => {
    setEditingSubtaskIndex(index);
    setEditedSubtaskTitle(subtaskList[index].title);
  };

  // Handle save subtask changes
  const handleSaveSubtask = (index) => {
    const updatedSubtasks = [...subtaskList];
    updatedSubtasks[index].title = editedSubtaskTitle;
    setSubtaskList(updatedSubtasks);
    setEditingSubtaskIndex(null);
  };

  // Handle cancel edit
  const handleCancelEdit = () => {
    setEditingSubtaskIndex(null);
    setEditedSubtaskTitle(""); // Reset the edited title
  };

  // Handle subtask deletion
  const handleDeleteSubtask = (index) => {
    setSubtaskList(subtaskList.filter((_, i) => i !== index));
  };

  // Handle new subtask addition
  const handleAddSubtask = () => {
    if (newSubtask.trim()) {
      setSubtaskList([...subtaskList, { title: newSubtask, completed: false }]);
      setNewSubtask("");
    }
  };

  return (
    <div className="my-4">
      <h3 className="text-lg font-semibold mb-2">Subtasks</h3>
      {subtaskList.length > 0 ? (
        <ul className="space-y-2">
          {subtaskList.map((subtask, index) => (
            <li
              key={index}
              className="flex items-center justify-between p-2 bg-gray-100 rounded-md"
            >
              <div className="flex items-center">
                {/* Subtask Checkbox */}
                <input
                  type="checkbox"
                  checked={subtask.completed}
                  onChange={() => handleToggleSubtask(index)}
                  className="mr-2"
                />

                {/* Editable Subtask Title */}
                {editingSubtaskIndex === index ? (
                  <input
                    type="text"
                    value={editedSubtaskTitle}
                    onChange={(e) => setEditedSubtaskTitle(e.target.value)}
                    className="border-b-2 p-1 focus:outline-none"
                    autoFocus
                    onBlur={() => handleSaveSubtask(index)}
                  />
                ) : (
                  <span
                    onClick={() => handleEditSubtask(index)}
                    className={`${
                      subtask.completed ? "line-through text-gray-400" : ""
                    } cursor-pointer`}
                  >
                    {subtask.title}
                  </span>
                )}
              </div>

              {/* Subtask Actions */}
              <div className="space-x-2">
                {editingSubtaskIndex === index ? (
                  <button
                    onClick={handleCancelEdit}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    Cancel
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => handleEditSubtask(index)}
                      className="text-blue-500"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeleteSubtask(index)}
                      className="text-red-500"
                    >
                      Delete
                    </button>
                  </>
                )}
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-500">No subtasks added yet.</p>
      )}

      {/* Add New Subtask */}
      <div className="mt-2 flex space-x-2">
        <input
          value={newSubtask}
          onChange={(e) => setNewSubtask(e.target.value)}
          className="flex-1 border p-1 rounded-md"
          placeholder="Add new subtask..."
        />
        <button
          onClick={handleAddSubtask}
          className="bg-blue-500 text-white px-3 py-1 rounded-md"
        >
          Add
        </button>
      </div>
    </div>
  );
};

const TaskComments = ({ comments = [] }) => {
  const [newComment, setNewComment] = useState("");
  const [commentList, setCommentList] = useState(comments);

  const handleAddComment = () => {
    if (newComment.trim()) {
      setCommentList([...commentList, newComment]);
      setNewComment("");
    }
  };

  return (
    <div className="my-4">
      <h3 className="text-lg font-semibold mb-2">Comments</h3>
      {commentList.length > 0 ? (
        <ul className="space-y-2">
          {commentList.map((comment, index) => (
            <li key={index} className="p-2 bg-gray-100 rounded-md">
              {comment}
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-500">No comments yet.</p>
      )}
      <textarea
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
        placeholder="Add a comment..."
        className="w-full p-2 mt-2 border border-gray-300 rounded-md"
      />
      <button
        onClick={handleAddComment}
        className="bg-blue-500 text-white px-3 py-1 mt-2 rounded"
      >
        Add Comment
      </button>
    </div>
  );
};

const TaskSidebar = ({ task, onClose }) => {
  const [status, setStatus] = useState(task.status || "To Do");
  const [phase, setPhase] = useState(task.phase || "Planning");
  const [assignedUsers, setAssignedUsers] = useState(task.users || []);

  return (
    <div className="w-full space-y-6 md:max-w-xs bg-gray-50 min-h-96 rounded-md">
      <div className="flex justify-end hidden md:flex">
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      {/* Status */}
      <div>
        <h3 className="text-lg font-semibold mb-2">Status</h3>
        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md"
        >
          <option value="To Do">To Do</option>
          <option value="In Progress">In Progress</option>
          <option value="In Review">In Review</option>
          <option value="Completed">Completed</option>
        </select>
      </div>

      {/* Assigned Users */}
      <div>
        <h3 className="text-lg font-semibold mb-2">Assigned Users</h3>
        <div className="flex -space-x-3">
          {assignedUsers.map((user, index) => (
            <div key={index} className="flex items-center">
              <span
                className="h-8 w-8 rounded-full bg-blue-600 text-white flex items-center justify-center border border-white"
                style={{ fontSize: "12px" }}
              >
                {getInitials(user.username)}
              </span>
            </div>
          ))}
        </div>
        <button className="text-blue-500 mt-2">+ Add User</button>
      </div>

      {/* Target Date */}
      {task.dueDate && (
        <div>
          <h3 className="text-lg font-semibold mb-2">Target Date</h3>
          <p className="text-gray-500">{task.dueDate}</p>
        </div>
      )}

      {/* Phase */}
      <div>
        <h3 className="text-lg font-semibold mb-2">Phase</h3>
        <select
          value={phase}
          onChange={(e) => setPhase(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md"
        >
          <option value="Planning">Planning</option>
          <option value="Design">Design</option>
          <option value="Development">Development</option>
          <option value="Testing">Testing</option>
          <option value="Deployment">Deployment</option>
        </select>
      </div>

      {/* Creation and Last Modified Dates */}
      <div>
        <h3 className="text-lg font-semibold mb-2">Dates</h3>
        <p className="text-gray-500">Created: {task.creationDate}</p>
        <p className="text-gray-500">Last Modified: {task.modifiedDate}</p>
      </div>
    </div>
  );
};

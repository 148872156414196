import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import DefaultCard from "../../../components/common/cards/DefaultCard";
import ProjectButtonGroupMenu from "../../../components/common/buttonGroup/ProjectButtonGroupMenu";
import ProjectFinance from "./project/ProjectFinance";
import ProjectFiles from "./project/ProjectFiles";
import ProjectTasks from "./project/ProjectTasks";
import ProjectSettings from "./project/ProjectSettings";
import TasksButtonGroupMenu from "../../../components/common/buttonGroup/TasksButtonGroupMenu";
import NoProjectsCard from "../../../components/common/cards/NoProjectsCard";
import { useProject } from "../../../hooks/useProjects/useProjects";
import LoadingPage from "../../../components/common/empty/LoadingPage";

const Project = () => {
  const { id } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);
  const initialViewMode = queryParams.get("view") || "files";

  const { data: project, isLoading, isError } = useProject(id);
  const [viewMode, setViewMode] = useState(initialViewMode);
  const [viewModeTasks, setViewModeTasks] = useState("board");

  useEffect(() => {
    navigate(`?view=${viewMode}`, { replace: true });
  }, [viewMode, navigate]);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (isError || !project) {
    return <NoProjectsCard />;
  }

  return (
    <div>
      <PageHeadings title={project?.name} tags={[]} showCta={false} />
      <ProjectButtonGroupMenu viewMode={viewMode} setViewMode={setViewMode}>
        {viewMode === "tasks" && (
          <TasksButtonGroupMenu
            viewMode={viewModeTasks}
            setViewMode={setViewModeTasks}
          />
        )}
      </ProjectButtonGroupMenu>

      {viewMode === "files" && (
        <DefaultCard>
          <ProjectFiles />
        </DefaultCard>
      )}
      {viewMode === "tasks" && (
        <ProjectTasks projectId={project?.id} viewMode={viewModeTasks} />
      )}
      {viewMode === "finance" && (
        <DefaultCard>
          <ProjectFinance projectId={project?.id} />
        </DefaultCard>
      )}
      {viewMode === "settings" && (
        <DefaultCard>
          <ProjectSettings data={project} />
        </DefaultCard>
      )}
    </div>
  );
};

export default Project;

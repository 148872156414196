import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import DefaultCard from "../../../components/common/cards/DefaultCard";
import FiltersContainer from "../../../components/common/filters/FiltersContainer";
import TableContainer from "../../../components/common/tables/TableContainer";
import Pagination from "../../../components/common/tables/Pagination";
import { PURCHASE_ORDERS_TABLE_COLUMNS } from "../../../utils/tableColumns";
import { useCompanyExpenses } from "../../../hooks/useExpenses/useExpenses";
import PurchaseOrder from "./PurchaseOrder";
import SlideOver from "../../../components/layout/SlideOver";

const PurchaseOrders = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sideOveOpen, setSideOverOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [perPage] = useState(10);
  const navigate = useNavigate();

  const { data, isLoading, isError, refetch } = useCompanyExpenses(
    currentPage,
    perPage
  );

  const handleAddExpense = () => {
    navigate("/purchase-orders/create");
  };

  const handlePageChange = (page) => setCurrentPage(page);

  const handleRowClick = (data) => {
    setSideOverOpen((prevState) => !prevState);
    setSelectedRow(data || {});
  };

  const expenses = data?.expenses || [];
  const totalItems = data?.pagination?.total_items || 0;

  return (
    <div>
      <PageHeadings
        title="Expenses"
        tags={[]}
        showCta={true}
        ctaLabel="Add Expense"
        handleCtaAction={handleAddExpense}
      />
      <DefaultCard>
        <FiltersContainer />
        <TableContainer
          hasCheckbox={true}
          columns={PURCHASE_ORDERS_TABLE_COLUMNS}
          rowType="purchase_orders"
          data={expenses}
          loading={isLoading}
          error={isError}
          handleRowClick={handleRowClick}
        />
        <Pagination
          currentPage={currentPage}
          totalCount={totalItems}
          pageSize={perPage}
          onPageChange={handlePageChange}
        />
      </DefaultCard>
      <SlideOver
        open={sideOveOpen}
        setOpen={setSideOverOpen}
        title="Expense Details"
      >
        <PurchaseOrder
          data={selectedRow}
          closeSidebar={() => setSideOverOpen(false)}
        />
      </SlideOver>
    </div>
  );
};

export default PurchaseOrders;

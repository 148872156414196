import React, { useState } from "react";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import TaskList from "./viewMode/TaskList";
import { MOCK_TASKS } from "../../../utils/mockData";
import TaskBoard from "./viewMode/TaskBoard";
import TasksButtonGroupMenu from "../../../components/common/buttonGroup/TasksButtonGroupMenu";

const TodoList = () => {
  const [tasks] = useState(MOCK_TASKS);
  const [viewMode, setViewMode] = useState("board");

  return (
    <div>
      <PageHeadings title={`Tasks`} tags={[]} showCta={false}>
        <TasksButtonGroupMenu viewMode={viewMode} setViewMode={setViewMode} />
      </PageHeadings>
      {viewMode === "list" ? (
        <TaskList data={tasks} />
      ) : (
        <TaskBoard data={tasks} />
      )}
    </div>
  );
};

export default TodoList;

import React from "react";

const MOCK_DATA = {
  totalRevenue: "$12,300",
  pendingPayments: "$4,500",
  totalProjects: 8,
  outstandingPOs: "$3,200",
};

const MainStats = ({ data = MOCK_DATA }) => {
  return (
    <>
      <StatCard title="Total Revenue" value={data.totalRevenue} />
      <StatCard title="Pending Payments" value={data.pendingPayments} />
      <StatCard title="Pending Expenses" value={data.outstandingPOs} />
      <StatCard title="Total Projects" value={data.totalProjects} />
    </>
  );
};

const StatCard = ({ title, value }) => {
  return (
    <div className="rounded-lg overflow-hidden shadow-sm bg-white p-6 lg:col-span-1 xl:col-span-1">
      <div className="flex items-center">
        <div className="ml-4">
          <h3 className="text-md font-semibold text-gray-700">{title}</h3>
          <p className="text-2xl font-bold text-gray-900">{value}</p>
        </div>
      </div>
    </div>
  );
};

export default MainStats;

import React from "react";

const Pagination = ({
  totalCount = 10, // Total number of items
  pageSize = 10, // Number of items per page
  currentPage = 1, // Current page
  onPageChange = () => {}, // Callback for changing page
}) => {
  // Calculate the total number of pages
  const totalPages = Math.ceil(totalCount / pageSize);

  // Generate an array for pagination buttons
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <nav
      aria-label="Page navigation"
      className="flex justify-between items-center p-4 w-full rounded-b-lg bg-gray-50"
    >
      {/* Help text */}
      <span className="text-sm text-gray-700">
        Showing{" "}
        <span className="font-semibold text-gray-900">
          {(currentPage - 1) * pageSize + 1}
        </span>{" "}
        to{" "}
        <span className="font-semibold text-gray-900">
          {Math.min(currentPage * pageSize, totalCount)}
        </span>{" "}
        of <span className="font-semibold text-gray-900">{totalCount}</span>
      </span>
      {/* Pagination */}
      <ul className="inline-flex items-center -space-x-px text-sm">
        <li>
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`flex items-center justify-center px-3 h-8 leading-tight border border-e-0 rounded-s
            ${
              currentPage === 1
                ? "text-gray-500 bg-gray-200 cursor-not-allowed"
                : "text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700"
            }`}
          >
            Previous
          </button>
        </li>
        {pages.map((p) => (
          <li key={p}>
            <button
              aria-current={p === currentPage ? "page" : undefined}
              className={`flex items-center justify-center px-3 h-8 leading-tight border text-gray-500 bg-white`}
            >
              {p}
            </button>
          </li>
        ))}
        <li>
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`flex items-center justify-center px-3 h-8 leading-tight border rounded-e 
            ${
              currentPage === totalPages
                ? "text-gray-500 bg-gray-200 cursor-not-allowed"
                : "text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700"
            }`}
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;

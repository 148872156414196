import React, { useState } from "react";

const TaskCreationModal = ({ projectId, onClose, onSubmit }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [initialDate, setInitialDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    // Validate title is not empty
    if (!title.trim()) {
      setError("Title is required.");
      return;
    }

    try {
      setError(""); // Clear any previous errors
      await onSubmit({
        title,
        description,
        initial_date: initialDate || null,
        end_date: endDate || null,
        project_id: projectId,
      });
      setTitle("");
      setDescription("");
      setInitialDate("");
      setEndDate("");
      onClose();
    } catch (err) {
      setError("Something went wrong. Please try again later.");
    }
  };

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
        <h3 className="text-2xl font-semibold mb-4">Create New Task</h3>
        {error && (
          <div className="bg-red-100 text-red-700 p-2 rounded mb-4">
            {error}
          </div>
        )}

        {/* Task Title */}
        <div className="mb-4">
          <label
            htmlFor="task-title"
            className="block text-sm font-medium text-gray-700"
          >
            Title
          </label>
          <input
            id="task-title"
            type="text"
            placeholder="Enter a descriptive title"
            className={`w-full border p-2 rounded mt-1 ${
              error && !title.trim() ? "border-red-500" : ""
            }`}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        {/* Task Description */}
        <div className="mb-4">
          <label
            htmlFor="task-description"
            className="block text-sm font-medium text-gray-700"
          >
            Description (Optional)
          </label>
          <textarea
            id="task-description"
            placeholder="Provide a detailed description of the task"
            className="w-full border p-2 rounded mt-1"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        {/* Task Dates */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
          <div>
            <label
              htmlFor="initial-date"
              className="block text-sm font-medium text-gray-700"
            >
              Start Date (Optional)
            </label>
            <input
              id="initial-date"
              type="date"
              className="w-full border p-2 rounded mt-1"
              value={initialDate}
              onChange={(e) => setInitialDate(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="end-date"
              className="block text-sm font-medium text-gray-700"
            >
              Due Date (Optional)
            </label>
            <input
              id="end-date"
              type="date"
              className="w-full border p-2 rounded mt-1"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>

        {/* Buttons */}
        <div className="flex justify-end mt-6">
          <button
            onClick={onClose}
            className="mr-3 bg-gray-200 hover:bg-gray-300 text-gray-700 font-medium px-4 py-2 rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="bg-blue-500 hover:bg-blue-600 text-white font-medium px-4 py-2 rounded-md"
          >
            Create Task
          </button>
        </div>
      </div>
    </div>
  );
};

export default TaskCreationModal;

// axiosInstance.js
import axios from "axios";
import { removeCurrentUser } from "../services/auth";

const axiosInstance = axios.create({
  baseURL: "https://your-api-base-url.com",
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      handleLogout();
    }
    return Promise.reject(error);
  }
);

const handleLogout = () => {
  removeCurrentUser();
  window.location.href = "/login";
};

export default axiosInstance;

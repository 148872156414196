import React, { useContext, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../../../context/AuthProvider";
import CardHeadings from "../../../../components/common/pageHeadings/CardHeadings";

const Billing = () => {
  const { user } = useContext(AuthContext);
  const [loadingButton, setLoadingButton] = useState(null); // Tracks which button is loading

  const handleCheckout = async (priceId) => {
    if (!priceId || !user) {
      alert("Please provide user details to proceed.");
      return;
    }

    try {
      setLoadingButton(priceId); // Set the loading state for the clicked button
      const response = await axios.post(
        "http://localhost:5000/api/v1/stripe/create-checkout-session",
        {
          price_id: priceId,
          email: user?.email,
        }
      );
      window.open(response.data.url, "_blank");
    } catch (error) {
      console.error("Error creating checkout session:", error);
      alert("Failed to create checkout session. Please try again.");
    } finally {
      setLoadingButton(null); // Reset loading state
    }
  };

  return (
    <div className="bg-white rounded-md p-4 border border-gray-200 w-full">
      <CardHeadings
        title="Choose the Right Plan for Your Business"
        description="Whether you’re just starting out or managing a growing team, we’ve got a plan for you."
      />
      <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 lg:space-y-0 p-4">
        {/* Basic Plan */}
        <div className="flex flex-col p-6 mx-auto lg:mr-0 lg:ml-auto max-w-md text-center text-gray-900 bg-white rounded-lg border border-gray-200 shadow">
          <h3 className="mb-4 text-2xl font-semibold">{`Pro`}</h3>
          <p className="font-light text-gray-500 sm:text-lg">
            {`Para estudios en crecimiento que necesitan más flexibilidad.`}
          </p>
          <div className="flex justify-center items-baseline my-8">
            <span className="mr-2 text-5xl font-extrabold">$29</span>
            <span className="text-gray-500">/user/month</span>
          </div>
          <ul className="mb-8 space-y-4 text-left">
            <li className="flex items-center space-x-3">
              <svg
                className="flex-shrink-0 w-5 h-5 text-green-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>
                Proyectos <b>ilimitados.</b>
              </span>
            </li>
            <li className="flex items-center space-x-3">
              <svg
                className="flex-shrink-0 w-5 h-5 text-green-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>
                Clientes <b>ilimitados.</b>
              </span>
            </li>
            <li className="flex items-center space-x-3">
              <svg
                className="flex-shrink-0 w-5 h-5 text-green-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>Acceso completo</span>
            </li>
            <li className="flex items-center space-x-3">
              <svg
                className="flex-shrink-0 w-5 h-5 text-green-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>Soporte prioritario</span>
            </li>
          </ul>
          <button
            onClick={() => handleCheckout("price_1QPaVMJk2hQsxH6pbVl3Jhx6")}
            className="self-end mt-auto w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            disabled={loadingButton === "price_1QPaVMJk2hQsxH6pbVl3Jhx6"} // Disable specific button
          >
            {loadingButton === "price_1QPaVMJk2hQsxH6pbVl3Jhx6"
              ? "Processing..."
              : "Upgrade"}
          </button>
        </div>

        {/* Standard Plan */}
        <div className="flex flex-col p-6 mx-auto lg:mr-0 lg:ml-auto max-w-md text-center text-gray-900 bg-white rounded-lg border border-gray-200 shadow">
          <h3 className="mb-4 text-2xl font-semibold">{`Enterprise`}</h3>
          <p className="font-light text-gray-500 sm:text-lg">
            {`Para equipos con necesidades mas robustas.`}
          </p>
          <div className="flex justify-center items-baseline my-8">
            <span className="mr-2 text-5xl font-extrabold">$39</span>
            <span className="text-gray-500">/user/month</span>
          </div>
          <ul className="mb-8 space-y-4 text-left">
            <li className="flex items-center space-x-3">
              <svg
                className="flex-shrink-0 w-5 h-5 text-green-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>
                A partir de <b>5 usuarios.</b>
              </span>
            </li>
            <li className="flex items-center space-x-3">
              <svg
                className="flex-shrink-0 w-5 h-5 text-green-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>
                Proyectos <b>ilimitados.</b>
              </span>
            </li>
            <li className="flex items-center space-x-3">
              <svg
                className="flex-shrink-0 w-5 h-5 text-green-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>
                Clientes <b>ilimitados.</b>
              </span>
            </li>
            <li className="flex items-center space-x-3">
              <svg
                className="flex-shrink-0 w-5 h-5 text-green-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>Acceso completo</span>
            </li>
            <li className="flex items-center space-x-3">
              <svg
                className="flex-shrink-0 w-5 h-5 text-green-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>Soporte prioritario</span>
            </li>
          </ul>
          <button
            onClick={() => handleCheckout("price_1QPaVhJk2hQsxH6p06KRZA3v")}
            className="self-end mt-auto w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            disabled={loadingButton === "price_1QPaVhJk2hQsxH6p06KRZA3v"} // Disable specific button
          >
            {loadingButton === "price_1QPaVhJk2hQsxH6p06KRZA3v"
              ? "Processing..."
              : "Upgrade"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Billing;

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import DefaultCard from "../../../components/common/cards/DefaultCard";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import AsyncSelect from "react-select/async";
import { useCreateExpense } from "../../../hooks/useExpenses/useExpenses";
import { useProjects } from "../../../hooks/useProjects/useProjects";
import { useProviders } from "../../../hooks/useProviders/useProviders";
import "react-phone-input-2/lib/style.css";

const CreatePurchaseOrder = () => {
  const location = useLocation();
  const paramProjectId = new URLSearchParams(location.search).get("projectId");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    description: "",
    amount: 0,
    due_date: "",
    status: "Pending",
    location: "",
    payment_type: "Credit Card",
    items: {},
    project_id: paramProjectId || "", // Use URL param if available
    provider_id: "",
  });
  const [error, setError] = useState({});
  const createExpense = useCreateExpense();

  // Fetch projects and providers
  const { data: projectsData } = useProjects(1, 10);
  const { data: providersData } = useProviders(1, 10);

  // Set up options for AsyncSelect dropdowns
  const projectOptions =
    projectsData?.projects.map((project) => ({
      value: project.id,
      label: project.name,
    })) || [];
  const providerOptions =
    providersData?.providers.map((provider) => ({
      value: provider.id,
      label: provider.company_name,
    })) || [];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle changes to items within the form
  const handleItemChange = (itemId, field, value) => {
    setFormData((prevData) => {
      const updatedItems = {
        ...prevData.items,
        [itemId]: {
          ...prevData.items[itemId],
          [field]:
            field === "value" ? Math.max(0, parseFloat(value) || 0) : value,
        },
      };
      const totalAmount = Object.values(updatedItems).reduce(
        (sum, item) => sum + (parseFloat(item.value) || 0),
        0
      );
      return { ...prevData, items: updatedItems, amount: totalAmount };
    });
  };

  const addItem = () => {
    const newItemId = `item-${Date.now()}`;
    setFormData((prevData) => ({
      ...prevData,
      items: { ...prevData.items, [newItemId]: { name: "", value: 0 } },
    }));
  };

  const removeItem = (itemId) => {
    setFormData((prevData) => {
      const updatedItems = { ...prevData.items };
      delete updatedItems[itemId];
      const totalAmount = Object.values(updatedItems).reduce(
        (sum, item) => sum + (parseFloat(item.value) || 0),
        0
      );
      return { ...prevData, items: updatedItems, amount: totalAmount };
    });
  };

  const validateInputs = () => {
    const errors = {};
    if (!formData.description.trim())
      errors.description = "Description is required.";
    if (formData.amount <= 0)
      errors.amount = "Total amount must be greater than 0.";
    Object.keys(formData.items).forEach((itemId) => {
      const item = formData.items[itemId];
      if (!item.name.trim())
        errors[`itemName${itemId}`] = "Item name is required.";
      if (!item.value || isNaN(item.value) || item.value <= 0)
        errors[`itemValue${itemId}`] = "Item value must be greater than 0.";
    });
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({});
    if (!validateInputs()) return;

    try {
      await createExpense.mutateAsync(formData);

      const redirectUrl = new URLSearchParams(location.search).get("redirect")
        ? `${new URLSearchParams(location.search).get("redirect")}?view=finance`
        : `/purchase-orders`;
      navigate(redirectUrl);
    } catch (error) {
      setError({ submit: "Failed to create expense. Please try again." });
    }
  };

  return (
    <div>
      <PageHeadings title="Add New Expense" tags={[]} showCta={false} />
      <DefaultCard>
        <div className="p-4 bg-white rounded-md">
          {error.submit && (
            <div className="text-red-500 p-4 mb-4 bg-red-50 rounded">
              {error.submit}
            </div>
          )}
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Description Field */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <input
                type="text"
                name="description"
                placeholder="Description of the expense"
                value={formData.description}
                onChange={handleChange}
                required
                className="border p-2 w-full rounded max-w-md"
              />
              {error.description && (
                <div className="text-red-500 text-sm mt-1">
                  {error.description}
                </div>
              )}
            </div>

            {/* Status Dropdown */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Status
              </label>
              <select
                name="status"
                value={formData.status}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
                required
              >
                <option value="Pending">Pending</option>
                <option value="Approved">Approved</option>
                <option value="In Process">In Process</option>
                <option value="Completed">Completed</option>
                <option value="Cancelled">Cancelled</option>
              </select>
              {error.status && (
                <div className="text-red-500 text-sm mt-1">{error.status}</div>
              )}
            </div>

            {/* Payment Due Date */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Payment Due Date (optional)
              </label>
              <input
                type="date"
                name="due_date"
                placeholder="Select due date for payment"
                value={formData.due_date}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              />
            </div>

            {/* Payment Type Dropdown */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Payment Type
              </label>
              <select
                name="payment_type"
                value={formData.payment_type}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              >
                <option value="Credit Card">Credit Card</option>
                <option value="Bank Transfer">Bank Transfer</option>
                <option value="Cash">Cash</option>
                <option value="Check">Check</option>
                <option value="Wire Transfer">Wire Transfer</option>
                <option value="Mobile Payment">Mobile Payment</option>
                <option value="Digital Wallet">
                  Digital Wallet (e.g., PayPal)
                </option>
                <option value="Other">Other</option>
              </select>
            </div>

            {/* Location */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Location
              </label>
              <input
                type="text"
                name="location"
                placeholder="Expense location (optional)"
                value={formData.location}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              />
            </div>

            {/* Project Dropdown (Hide if projectId is in params) */}
            {!paramProjectId && (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Project
                </label>
                <AsyncSelect
                  className="max-w-md"
                  cacheOptions
                  defaultOptions={projectOptions}
                  loadOptions={(inputValue, callback) =>
                    callback(
                      projectOptions.filter((option) =>
                        option.label
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      )
                    )
                  }
                  onChange={(selected) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      project_id: selected ? selected.value : "",
                    }))
                  }
                  placeholder="Select a project"
                />
              </div>
            )}

            {/* Provider Dropdown */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Provider
              </label>
              <AsyncSelect
                className="max-w-md"
                cacheOptions
                defaultOptions={providerOptions}
                loadOptions={(inputValue, callback) =>
                  callback(
                    providerOptions.filter((option) =>
                      option.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    )
                  )
                }
                onChange={(selected) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    provider_id: selected ? selected.value : "",
                  }))
                }
                placeholder="Select a provider"
              />
            </div>

            {/* Items Table */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Items
              </label>
              <table className="text-sm w-full max-w-md">
                <thead>
                  <tr>
                    <th className="border-b py-2">Item Name</th>
                    <th className="border-b py-2">Item Value</th>
                    <th className="border-b py-2"></th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(formData.items).map(
                    ([itemId, item], index) => (
                      <tr key={itemId}>
                        <td className="border-b py-2">
                          <input
                            type="text"
                            placeholder="Item Name"
                            value={item.name}
                            onChange={(e) =>
                              handleItemChange(itemId, "name", e.target.value)
                            }
                            className="border p-2 w-full rounded"
                          />
                          {error[`itemName${itemId}`] && (
                            <div className="text-red-500 text-xs mt-1">
                              {error[`itemName${itemId}`]}
                            </div>
                          )}
                        </td>
                        <td className="border-b py-2">
                          <input
                            type="text"
                            placeholder="Item Value"
                            value={
                              item.value
                                ? new Intl.NumberFormat().format(item.value)
                                : ""
                            }
                            onChange={(e) => {
                              const numericValue = e.target.value.replace(
                                /,/g,
                                ""
                              );
                              if (numericValue > 0) {
                                handleItemChange(itemId, "value", numericValue);
                              }
                            }}
                            className="border p-2 w-full rounded"
                          />
                          {error[`itemValue${itemId}`] && (
                            <div className="text-red-500 text-xs mt-1">
                              {error[`itemValue${itemId}`]}
                            </div>
                          )}
                        </td>
                        <td className="border-b py-2">
                          <button
                            type="button"
                            onClick={() => removeItem(itemId)}
                            className="text-red-500"
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <button
                type="button"
                onClick={addItem}
                className="mt-2 text-blue-500"
              >
                Add Item
              </button>
            </div>

            {/* Amount Field (Read-Only) */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Total Amount
              </label>
              <input
                type="text"
                name="amount"
                value={
                  formData.amount
                    ? new Intl.NumberFormat().format(formData.amount)
                    : 0
                }
                readOnly
                disabled
                className="border p-2 w-full rounded max-w-md bg-gray-100 cursor-not-allowed"
              />
            </div>

            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded"
              disabled={createExpense.isLoading}
            >
              {createExpense.isLoading ? "Adding..." : "Add Expense"}
            </button>
          </form>
        </div>
      </DefaultCard>
    </div>
  );
};

export default CreatePurchaseOrder;

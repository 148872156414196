import React, { useState } from "react";
import { format } from "date-fns";
import Tabs from "../../../components/common/tabs/Tabs";
import {
  useDeleteInvoice,
  useUpdateInvoice,
} from "../../../hooks/useInvoices/useInvoices";
import EditInvoice from "./details/EditInvoice";
import ExpenseItems from "../purchaseOrders/details/ExpenseItems";
import InvoiceClient from "./details/InvoiceClient";
import ClientProjects from "../clients/details/ClientProjects";
import Badge from "../../../components/common/badges/Badge";
import InvoiceItems from "./details/InvoiceItems";
import { getStatusBadgeClass } from "../../../components/common/tables/TableRows";

const InvoiceDetails = ({ data, closeSidebar }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState({});
  const [currentInvoice, setCurrentInvoice] = useState(data);
  const [showOptions, setShowOptions] = useState(false);

  const updateInvoice = useUpdateInvoice();
  const deleteInvoice = useDeleteInvoice();

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleDelete = async () => {
    setShowOptions(false);
    if (window.confirm("Are you sure you want to delete this expense?")) {
      try {
        await deleteInvoice.mutateAsync({ invoiceId: currentInvoice.id });
        closeSidebar();
      } catch (err) {
        setError({ submit: "Failed to delete expense. Please try again." });
      }
    }
  };

  const handleInvoiceUpdate = (updatedInvoice) => {
    setCurrentInvoice((prev) => ({
      ...prev,
      ...updatedInvoice,
    }));
    setIsEditing(false);
  };

  const tabs = [
    {
      label: "Items",
      content: (
        <InvoiceItems
          currentInvoice={currentInvoice}
          items={Object?.values(currentInvoice?.items || {})}
        />
      ),
    },
    {
      label: "Project",
      content: (
        <ClientProjects
          data={currentInvoice?.project ? [currentInvoice?.project] : []}
          title={"Invoice Not Linked to Project."}
          description={
            "Please edit this invoice or create a project to associate it."
          }
        />
      ),
    },
    {
      label: "Client",
      content: <InvoiceClient data={currentInvoice?.client} />,
    },
  ];

  return (
    <div className="space-y-6 p-6">
      {error.submit && (
        <div className="p-4 mb-4 text-sm text-red-800 bg-red-50 rounded-lg">
          {error.submit}
        </div>
      )}
      {isEditing ? (
        <EditInvoice
          invoiceData={currentInvoice}
          onCancel={handleEditToggle}
          onSave={handleInvoiceUpdate}
        />
      ) : (
        <div>
          <div className="space-y-2 mb-4">
            <div className="flex items-center justify-between mb-4">
              <div>
                <h5 className="text-xl font-bold leading-none text-gray-900">
                  {currentInvoice?.invoice_number || "---"}
                </h5>
                <span
                  className={`text-xs font-medium px-2.5 py-0.5 rounded ${getStatusBadgeClass(
                    currentInvoice?.status
                  )}`}
                >
                  {currentInvoice?.status}
                </span>
              </div>
              <div className="relative">
                <button
                  onClick={() => setShowOptions(!showOptions)}
                  className="p-1 rounded hover:bg-gray-200"
                >
                  {/* Dropdown Icon */}
                  <svg
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6.75a.75.75 0 0 1 0-1.5.75.75 0 0 1 0 1.5Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 12.75a.75.75 0 0 1 0-1.5.75.75 0 0 1 0 1.5Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 18.75a.75.75 0 0 1 0-1.5.75.75 0 0 1 0 1.5Z"
                    />
                  </svg>
                </button>
                {/* Dropdown Menu */}
                {showOptions && (
                  <div className="absolute right-0 mt-2 w-32 bg-white border rounded shadow-lg">
                    <button
                      onClick={handleEditToggle}
                      className="block w-full px-4 py-2 text-left text-sm hover:bg-gray-100"
                    >
                      Edit
                    </button>
                    <button
                      onClick={handleDelete}
                      className="block w-full px-4 py-2 text-left text-sm text-red-600 hover:bg-gray-100"
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="space-y-4">
              <div className="flex items-center space-x-2">
                <div>
                  <p className="text-sm text-gray-500">Issue Date</p>
                  <p className="text-lg font-semibold text-gray-800">
                    {currentInvoice?.issue_date
                      ? format(
                          new Date(currentInvoice.issue_date),
                          "MMMM dd, yyyy"
                        )
                      : "---"}
                  </p>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <div>
                  <p className="text-sm text-gray-500">Due Date</p>
                  <p className="text-lg font-semibold text-gray-800">
                    {currentInvoice?.due_date
                      ? format(
                          new Date(currentInvoice.due_date),
                          "MMMM dd, yyyy"
                        )
                      : "---"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Tabs */}
          <div className="mt-6">
            <Tabs tabs={tabs} />
          </div>
        </div>
      )}
    </div>
  );
};

export default InvoiceDetails;

import React from "react";
import { useNavigate } from "react-router-dom";

const NoProjectsCard = () => {
  const navigate = useNavigate();

  const handleCreateProject = () => {
    navigate("/projects/create");
  };

  return (
    <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md text-center">
      <h2 className="text-2xl font-semibold text-gray-700 mb-2">
        No Projects Found
      </h2>
      <p className="text-gray-500 mb-4">
        You haven’t created any projects yet. Start by creating a project to
        organize your work and collaborate with your team.
      </p>
      <button
        onClick={handleCreateProject}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
        Create Your First Project
      </button>
    </div>
  );
};

export default NoProjectsCard;

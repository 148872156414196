import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}/auth`;

export const register = (companyName, username, email, password) => {
  return axios.post(`${API_URL}/register`, {
    companyName,
    username,
    email,
    password,
  });
};

export const login = (email, password) => {
  return axios.post(`${API_URL}/login`, { email, password });
};

export const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export const setCurrentUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const removeCurrentUser = () => {
  localStorage.removeItem("user");
};

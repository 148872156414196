import React from "react";
import TaskList from "../../todoList/viewMode/TaskList";
import TaskBoard from "../../todoList/viewMode/TaskBoard";
import { useTasksOfProject } from "../../../../hooks/useTasks/useTasks";

const ProjectTasks = ({ projectId, viewMode }) => {
  const { data: tasks, isLoading, isError } = useTasksOfProject(projectId);

  return (
    <div>
      {viewMode === "list" ? (
        <TaskList data={tasks} projectId={projectId} />
      ) : (
        <TaskBoard data={tasks} projectId={projectId} />
      )}
    </div>
  );
};

export default ProjectTasks;

import React from "react";

const Quote = () => {
  return (
    <section className="bg-slate-100">
      <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
        <figure className="max-w-screen-md mx-auto">
          <blockquote>
            <p className="text-2xl font-normal text-gray-900 mb-4">
              {`Ayudamos a simplificar la operación y mejorar la rentabilidad,
          para que los diseñadores y arquitectos se concentren en lo que
          mejor hacen…`}
              <br />
            </p>
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900">
              {`Crear espacios únicos y memorables.`}{" "}
            </h2>
          </blockquote>
        </figure>
      </div>
    </section>
  );
};

export default Quote;

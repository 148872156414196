import React from "react";
import { Link } from "react-router-dom";
import ProjectCard from "../../../../components/common/cards/ProjectCard";

const ClientProjects = ({
  data,
  title = "This client has no projects yet",
  description = "Start by creating the first project for this client and kick off your journey to delivering valuable services.",
}) => {
  return (
    <div>
      {data && data.length > 0 ? (
        <div className="mb-4 grid gap-4">
          {data?.map((project) => (
            <ProjectCard key={project?.id} data={project} />
          ))}
        </div>
      ) : (
        <div className="p-6 border border-dashed border-gray-300 rounded-lg bg-gray-50 text-center">
          <h3 className="text-lg font-semibold text-gray-700">{title}</h3>
          <p className="text-gray-500 mt-2 mb-4">{description}</p>
          <Link
            to={"/projects/create"}
            className="bg-blue-500 text-white px-6 py-2 rounded shadow hover:bg-blue-600"
          >
            Create a Project
          </Link>
        </div>
      )}
    </div>
  );
};

export default ClientProjects;

import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import { AuthProvider, AuthContext } from "./context/AuthProvider";
import Landing from "./pages/Landing";
import Dashboard from "./pages/dashboard/Dashboard";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import Settings from "./pages/dashboard/settings/Settings";
import NotFound from "./pages/NotFound";
import Register from "./pages/auth/Register";
import Login from "./pages/auth/Login";
import ConfirmEmailSent from "./pages/auth/ConfirmEmailSent";
import ConfirmationSuccess from "./pages/auth/ConfirmationSuccess";
import SetPassword from "./pages/auth/SetPassword";
import Projects from "./pages/dashboard/projects/Projects";
import TodoList from "./pages/dashboard/todoList/TodoList";
import PurchaseOrders from "./pages/dashboard/purchaseOrders/PurchaseOrders";
import Invoices from "./pages/dashboard/invoices/Invoices";
import Clients from "./pages/dashboard/clients/Clients";
import Providers from "./pages/dashboard/providers/Providers";
// import ProductLibrary from "./pages/dashboard/productLibrary/ProductLibrary";
import TradePortal from "./pages/dashboard/tradePortal/TradePortal";
import Project from "./pages/dashboard/projects/Project";
import CreateClient from "./pages/dashboard/clients/CreateClient";
import CreateProvider from "./pages/dashboard/providers/CreateProvider";
import CreateProject from "./pages/dashboard/projects/CreateProject";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import CreatePurchaseOrder from "./pages/dashboard/purchaseOrders/CreatePurchaseOrder";
import PurchaseOrder from "./pages/dashboard/purchaseOrders/PurchaseOrder";
import CreateInvoice from "./pages/dashboard/invoices/CreateInvoice";
import CreateFile from "./pages/dashboard/files/CreateFile";
import SharedProject from "./pages/shareProject.js/ShareProject";

const PrivateRoute = () => {
  const { user, authInitialized } = useContext(AuthContext);

  if (!authInitialized) {
    return <div>Loading...</div>;
  }

  return user ? (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  ) : (
    <Navigate to="/login" replace />
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/confirm-email-sent" element={<ConfirmEmailSent />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route
            path="/confirmation-success"
            element={<ConfirmationSuccess />}
          />
          <Route path="/set-password/:token" element={<SetPassword />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="projects" element={<Projects />} />
            <Route path="projects/create" element={<CreateProject />} />
            <Route path="projects/:id" element={<Project />} />
            <Route path="projects/:id/create-file" element={<CreateFile />} />
            <Route path="todo-list" element={<TodoList />} />
            <Route path="purchase-orders" element={<PurchaseOrders />} />
            <Route
              path="purchase-orders/create"
              element={<CreatePurchaseOrder />}
            />
            <Route path="purchase-orders/:id" element={<PurchaseOrder />} />
            <Route path="invoices" element={<Invoices />} />
            <Route path="invoices/create" element={<CreateInvoice />} />
            <Route path="clients" element={<Clients />} />
            <Route path="clients/create" element={<CreateClient />} />
            <Route path="partners" element={<Providers />} />
            <Route path="partners/create" element={<CreateProvider />} />
            {/* <Route path="product-library" element={<ProductLibrary />} /> */}
            <Route path="trade-portal" element={<TradePortal />} />
            <Route path="settings" element={<Settings />} />
          </Route>
          <Route path="/shared/:token" element={<SharedProject />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;

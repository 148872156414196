export const PRODUCT_LIBRARY_MOCK_DATA = [
  {
    img: "https://images.squarespace-cdn.com/content/v1/554a7c69e4b0625ed8339704/1585848352310-MDU4STFMGWMZGLVAV4VO/Sofa%2Fsectional+with+movable+backs",
    hasTags: true,
    showHide: true,
    showFavorite: true,
    hasCta: false,
    tags: ["Modern Design"],
    title: "Contemporary Sofa",
    description:
      "A stylish sofa with clean lines, perfect for modern living rooms.",
    partner: "FurnitureCo",
    price: "$1,199.99",
    ctaLabel: "",
    handleHide: () => console.log("Hide clicked"),
    HandleFavorite: () => console.log("Favorite clicked"),
    handleCta: null,
  },
  {
    img: "https://assets.wfcdn.com/im/04689203/resize-h400-w400%5Ecompr-r85/2486/248640105/default_name.jpg",
    hasTags: true,
    showHide: true,
    showFavorite: true,
    hasCta: false,
    tags: ["Minimalist"],
    title: "Glass Coffee Table",
    description: "A sleek, minimalist coffee table with tempered glass top.",
    partner: "InteriorDesigns",
    price: "$349.99",
    ctaLabel: "",
    handleHide: () => console.log("Hide clicked"),
    HandleFavorite: () => console.log("Favorite clicked"),
    handleCta: null,
  },
  {
    img: "https://i5.walmartimages.com/seo/Queen-Size-Velvet-Upholstered-Bed-Frame-Platform-Frame-Storage-Drawers-Headboard-Strong-Wood-Slats-Support-No-Box-Spring-Needed-Blue_ec226d55-a777-481b-87fb-767f12fe09ec.c2876e3531b638cfee8d59ee6a5c02c3.jpeg?odnHeight=768&odnWidth=768&odnBg=FFFFFF",
    hasTags: true,
    showHide: true,
    showFavorite: true,
    hasCta: false,
    tags: [],
    title: "Velvet Upholstered Bed",
    description:
      "Luxurious king-size bed with velvet headboard for a cozy bedroom.",
    partner: "LuxuryFurniture",
    price: "$1,599.99",
    ctaLabel: "",
    handleHide: () => console.log("Hide clicked"),
    HandleFavorite: () => console.log("Favorite clicked"),
    handleCta: null,
  },
  {
    img: "https://static.wixstatic.com/media/70fe51_fdbfeb5d4129474785429b77b4989900~mv2.jpg/v1/fill/w_560,h_606,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/70fe51_fdbfeb5d4129474785429b77b4989900~mv2.jpg",
    hasTags: true,
    showHide: true,
    showFavorite: true,
    hasCta: false,
    tags: ["Rustic"],
    title: "Solid Oak Dining Table",
    description:
      "A large rustic dining table made from solid oak, ideal for family gatherings.",
    partner: "TimberCraft",
    price: "$899.99",
    ctaLabel: "",
    handleHide: () => console.log("Hide clicked"),
    HandleFavorite: () => console.log("Favorite clicked"),
    handleCta: null,
  },
  {
    img: "https://dm4c9mjc2jvtf.cloudfront.net/cms/1200Eames-Lounge-Chair-and-Ottoman-Tabouret-Bois-Akari-EVDM-Postermaster.jpg",
    hasTags: true,
    showHide: true,
    showFavorite: true,
    hasCta: false,
    tags: ["Ergonomic"],
    title: "Designer Lounge Chair",
    description:
      "A comfortable ergonomic chair with a mid-century modern aesthetic.",
    partner: "DesignerSeating",
    price: "$499.99",
    ctaLabel: "",
    handleHide: () => console.log("Hide clicked"),
    HandleFavorite: () => console.log("Favorite clicked"),
    handleCta: null,
  },
  {
    img: "https://tejidoslav.com/cdn/shop/products/GRACE_39771_336_1_1296x.jpg?v=1634652497",
    hasTags: true,
    showHide: true,
    showFavorite: true,
    hasCta: false,
    tags: ["Bohemian"],
    title: "Boho Area Rug",
    description:
      "A vibrant area rug with bohemian patterns, perfect for adding texture.",
    partner: "RugArtisans",
    price: "$299.99",
    ctaLabel: "",
    handleHide: () => console.log("Hide clicked"),
    HandleFavorite: () => console.log("Favorite clicked"),
    handleCta: null,
  },
  {
    img: "https://www.ikea.com/co/es/images/products/jaettesta-mueble-negro__1192812_pe901279_s5.jpg?f=xl",
    hasTags: true,
    showHide: true,
    showFavorite: true,
    hasCta: false,
    tags: ["Industrial"],
    title: "Metal Frame Bookshelf",
    description:
      "An industrial-style bookshelf with a sturdy metal frame and wooden shelves.",
    partner: "UrbanDecor",
    price: "$449.99",
    ctaLabel: "",
    handleHide: () => console.log("Hide clicked"),
    HandleFavorite: () => console.log("Favorite clicked"),
    handleCta: null,
  },
  {
    img: "https://cdn.webshopapp.com/shops/71227/files/201390410/550x550x2/scandinavian-floor-lamp-design-white-or-black-e27.jpg",
    hasTags: true,
    showHide: true,
    showFavorite: true,
    hasCta: false,
    tags: ["Scandinavian"],
    title: "Scandi Floor Lamp",
    description:
      "A simple yet elegant floor lamp, perfect for cozy, minimalistic spaces.",
    partner: "LightWorks",
    price: "$179.99",
    ctaLabel: "",
    handleHide: () => console.log("Hide clicked"),
    HandleFavorite: () => console.log("Favorite clicked"),
    handleCta: null,
  },
  {
    img: "https://assets.wfcdn.com/im/81160420/compr-r85/1565/156509341/Royal+Baroque+Hardwood+Wall+Mirror.jpg",
    hasTags: true,
    showHide: true,
    showFavorite: true,
    hasCta: false,
    tags: [],
    title: "Antique Wall Mirror",
    description:
      "A large, vintage-style mirror to create a sense of space in any room.",
    partner: "ClassicMirrors",
    price: "$349.99",
    ctaLabel: "",
    handleHide: () => console.log("Hide clicked"),
    HandleFavorite: () => console.log("Favorite clicked"),
    handleCta: null,
  },
];

export const SERVICE_PROVIDERS = [
  {
    id: 0,
    businessName: "Luxury Furniture Co.",
    providertype: "Furniture Supplier",
    services: ["Custom Furniture", "Delivery", "Installation"],
    contactPerson: "Sarah Thompson",
    location: {
      address: "123 Elm Street",
      city: "New York",
      state: "NY",
      zip: "10001",
      country: "USA",
    },
    phone: "+1-212-555-1234",
    email: "info@luxuryfurniture.com",
    website: "https://luxuryfurniture.com",
  },
  {
    id: 1,
    businessName: "Bright Lights Design",
    providertype: "Lighting Specialist",
    services: ["Lighting Consultation", "Custom Lighting", "Installation"],
    contactPerson: "David Wilson",
    location: {
      address: "456 Oak Avenue",
      city: "Los Angeles",
      state: "CA",
      zip: "90001",
      country: "USA",
    },
    phone: "+1-323-555-9876",
    email: "contact@brightlights.com",
    website: "https://brightlightsdesign.com",
  },
  {
    id: 2,
    businessName: "Eco Flooring Solutions",
    providertype: "Flooring Specialist",
    services: [
      "Hardwood Flooring",
      "Carpet Installation",
      "Eco-friendly Materials",
    ],
    contactPerson: "Emily Clarke",
    location: {
      address: "789 Maple Street",
      city: "Chicago",
      state: "IL",
      zip: "60601",
      country: "USA",
    },
    phone: "+1-312-555-5678",
    email: "support@ecoflooring.com",
    website: "https://ecoflooringsolutions.com",
  },
  {
    id: 3,
    businessName: "TimberCraft Furniture",
    providertype: "Furniture Manufacturer",
    services: ["Custom Wood Furniture", "Restoration", "Design Consultation"],
    contactPerson: "Michael Brown",
    location: {
      address: "102 Pine Lane",
      city: "Austin",
      state: "TX",
      zip: "73301",
      country: "USA",
    },
    phone: "+1-512-555-1111",
    email: "sales@timbercraft.com",
    website: "https://timbercraftfurniture.com",
  },
  {
    id: 4,
    businessName: "Urban Artisans",
    providertype: "Art and Decor Supplier",
    services: ["Wall Art", "Sculptures", "Custom Commissions"],
    contactPerson: "Jessica Martin",
    location: {
      address: "234 Cedar Boulevard",
      city: "San Francisco",
      state: "CA",
      zip: "94102",
      country: "USA",
    },
    phone: "+1-415-555-2222",
    email: "info@urbanartisans.com",
    website: "https://urbanartisans.com",
  },
  {
    id: 5,
    businessName: "GreenScape Landscaping",
    providertype: "Landscaping Service",
    services: ["Outdoor Spaces", "Garden Design", "Sustainable Landscaping"],
    contactPerson: "Peter Johnson",
    location: {
      address: "567 Birch Road",
      city: "Seattle",
      state: "WA",
      zip: "98101",
      country: "USA",
    },
    phone: "+1-206-555-3333",
    email: "hello@greenscapelandscaping.com",
    website: "https://greenscapelandscaping.com",
  },
  {
    id: 6,
    businessName: "Elegant Drapery",
    providertype: "Window Treatment Specialist",
    services: ["Custom Drapes", "Blinds", "Installation"],
    contactPerson: "Laura Perez",
    location: {
      address: "345 Willow Court",
      city: "Miami",
      state: "FL",
      zip: "33101",
      country: "USA",
    },
    phone: "+1-305-555-4444",
    email: "info@elegantdrapery.com",
    website: "https://elegantdrapery.com",
  },
  {
    id: 7,
    businessName: "DesignBuild Interiors",
    providertype: "General Contractor",
    services: ["Interior Remodeling", "Design-Build", "Project Management"],
    contactPerson: "John Davis",
    location: {
      address: "876 Sycamore Drive",
      city: "Dallas",
      state: "TX",
      zip: "75201",
      country: "USA",
    },
    phone: "+1-214-555-5555",
    email: "john@designbuildinteriors.com",
    website: "https://designbuildinteriors.com",
  },
  {
    id: 8,
    businessName: "SmartHome Solutions",
    providertype: "Technology Integrator",
    services: ["Smart Home Automation", "Security Systems", "Lighting Control"],
    contactPerson: "Mark Evans",
    location: {
      address: "909 Cherry Street",
      city: "Boston",
      state: "MA",
      zip: "02101",
      country: "USA",
    },
    phone: "+1-617-555-6666",
    email: "support@smarthomesolutions.com",
    website: "https://smarthomesolutions.com",
  },
  {
    id: 9,
    businessName: "Luxury Bath Designs",
    providertype: "Bathroom Design Specialist",
    services: ["Custom Bathrooms", "Spa Designs", "Shower Installation"],
    contactPerson: "Michelle Green",
    location: {
      address: "123 Palm Avenue",
      city: "Phoenix",
      state: "AZ",
      zip: "85001",
      country: "USA",
    },
    phone: "+1-602-555-7777",
    email: "contact@luxurybathdesigns.com",
    website: "https://luxurybathdesigns.com",
  },
];

export const PURCHASE_ORDERS_MOCK_DATA = [
  {
    order: "#001234",
    date: "2024-10-01",
    customer: "John Smith",
    total: "$1,299.00",
    status: "Shipped",
    items: "Sofa, Coffee Table",
    location: "New York, NY",
    payment_type: "Credit Card",
  },
  {
    order: "#001235",
    date: "2024-09-29",
    customer: "Emily Johnson",
    total: "$799.99",
    status: "Pending",
    items: "Dining Table",
    location: "Los Angeles, CA",
    payment_type: "PayPal",
  },
  {
    order: "#001236",
    date: "2024-09-28",
    customer: "Michael Brown",
    total: "$499.50",
    status: "Delivered",
    items: "Bed Frame, Mattress",
    location: "Chicago, IL",
    payment_type: "Credit Card",
  },
  {
    order: "#001237",
    date: "2024-09-26",
    customer: "Jessica Davis",
    total: "$2,499.00",
    status: "Shipped",
    items: "Sectional Sofa",
    location: "Miami, FL",
    payment_type: "Bank Transfer",
  },
  {
    order: "#001238",
    date: "2024-09-25",
    customer: "Daniel Garcia",
    total: "$199.99",
    status: "Delivered",
    items: "Floor Lamp",
    location: "Austin, TX",
    payment_type: "Credit Card",
  },
  {
    order: "#001239",
    date: "2024-09-24",
    customer: "Sophia Martinez",
    total: "$349.99",
    status: "Processing",
    items: "Area Rug",
    location: "San Francisco, CA",
    payment_type: "Credit Card",
  },
  {
    order: "#001240",
    date: "2024-09-22",
    customer: "Chris Lee",
    total: "$3,899.00",
    status: "Shipped",
    items: "Dining Set",
    location: "Seattle, WA",
    payment_type: "Wire Transfer",
  },
  {
    order: "#001241",
    date: "2024-09-21",
    customer: "Olivia Thompson",
    total: "$1,199.99",
    status: "Pending",
    items: "Bookshelf, Coffee Table",
    location: "Boston, MA",
    payment_type: "Credit Card",
  },
  {
    order: "#001242",
    date: "2024-09-19",
    customer: "Liam White",
    total: "$249.99",
    status: "Delivered",
    items: "Accent Chair",
    location: "Philadelphia, PA",
    payment_type: "PayPal",
  },
  {
    order: "#001243",
    date: "2024-09-18",
    customer: "Isabella King",
    total: "$4,500.00",
    status: "Shipped",
    items: "Complete Living Room Set",
    location: "Houston, TX",
    payment_type: "Bank Transfer",
  },
];

export const INVOICES_MOCK_DATA = [
  {
    invoice: "#INV-1001",
    total: "$1,299.00",
    status: "Paid",
    customer: "John Smith",
    issued_on: "2024-09-20",
    paid_on: "2024-09-22",
    type: "Compra de Mobiliario - Sala",
  },
  {
    invoice: "#INV-1002",
    total: "$799.99",
    status: "Pending",
    customer: "Emily Johnson",
    issued_on: "2024-09-21",
    paid_on: "-",
    type: "Consulta de Diseño - Cocina",
  },
  {
    invoice: "#INV-1003",
    total: "$2,499.00",
    status: "Paid",
    customer: "Michael Brown",
    issued_on: "2024-09-18",
    paid_on: "2024-09-20",
    type: "Sofá a Medida - Sala",
  },
  {
    invoice: "#INV-1004",
    total: "$499.50",
    status: "Paid",
    customer: "Jessica Davis",
    issued_on: "2024-09-19",
    paid_on: "2024-09-21",
    type: "Mobiliario de Dormitorio",
  },
  {
    invoice: "#INV-1005",
    total: "$199.99",
    status: "Overdue",
    customer: "Daniel Garcia",
    issued_on: "2024-09-15",
    paid_on: "-",
    type: "Iluminación de Pasillo",
  },
  {
    invoice: "#INV-1006",
    total: "$899.99",
    status: "Paid",
    customer: "Sophia Martinez",
    issued_on: "2024-09-12",
    paid_on: "2024-09-14",
    type: "Conjunto de Comedor",
  },
  {
    invoice: "#INV-1007",
    total: "$3,899.00",
    status: "Pending",
    customer: "Chris Lee",
    issued_on: "2024-09-10",
    paid_on: "-",
    type: "Mobiliario Personalizado - Oficina en Casa",
  },
  {
    invoice: "#INV-1008",
    total: "$1,199.99",
    status: "Overdue",
    customer: "Olivia Thompson",
    issued_on: "2024-09-08",
    paid_on: "-",
    type: "Consulta de Diseño - Dormitorio Principal",
  },
  {
    invoice: "#INV-1009",
    total: "$249.99",
    status: "Paid",
    customer: "Liam White",
    issued_on: "2024-09-05",
    paid_on: "2024-09-07",
    type: "Alfombra Personalizada - Sala de Estar",
  },
  {
    invoice: "#INV-1010",
    total: "$4,500.00",
    status: "Paid",
    customer: "Isabella King",
    issued_on: "2024-09-01",
    paid_on: "2024-09-04",
    type: "Diseño de Interiores Completo",
  },
];

export const CLIENTS_MOCK_DATA = [
  {
    id: 0,
    name: "Juan Pérez",
    email: "juan.perez@gmail.com",
    phone: "+1-212-555-1234",
    projects: 3,
    invoices: 5,
    pending_payments: "$300.00",
    status: "Client",
    last_project: "Remodelación de Sala",
  },
  {
    id: 1,
    name: "Ana Gómez",
    email: "ana.gomez@gmail.com",
    phone: "+1-323-555-9876",
    projects: 1,
    invoices: 2,
    pending_payments: "$200.00",
    status: "Client",
    last_project: "Consultoría de Diseño",
  },
  {
    id: 2,
    name: "Miguel Rodríguez",
    email: "miguel.rodriguez@gmail.com",
    phone: "+1-312-555-5678",
    projects: 0,
    invoices: 0,
    pending_payments: "$0.00",
    status: "Potential Client",
    last_project: "N/A",
  },
  {
    id: 3,
    name: "Jessica Ramírez",
    email: "jessica.ramirez@gmail.com",
    phone: "+1-512-555-1111",
    projects: 4,
    invoices: 7,
    pending_payments: "$1,499.00",
    status: "Client",
    last_project: "Renovación de Dormitorio Principal",
  },
  {
    id: 4,
    name: "Daniel García",
    email: "daniel.garcia@gmail.com",
    phone: "+1-206-555-3333",
    projects: 1,
    invoices: 1,
    pending_payments: "$0.00",
    status: "Client",
    last_project: "Iluminación de Pasillo",
  },
  {
    id: 5,
    name: "Sofía Martínez",
    email: "sofia.martinez@gmail.com",
    phone: "+1-305-555-4444",
    projects: 2,
    invoices: 4,
    pending_payments: "$349.99",
    status: "Client",
    last_project: "Diseño de Oficina en Casa",
  },
  {
    id: 6,
    name: "Cristian López",
    email: "cristian.lopez@gmail.com",
    phone: "+1-214-555-5555",
    projects: 0,
    invoices: 0,
    pending_payments: "$0.00",
    status: "Potential Client",
    last_project: "N/A",
  },
  {
    id: 7,
    name: "Olivia Torres",
    email: "olivia.torres@gmail.com",
    phone: "+1-617-555-6666",
    projects: 3,
    invoices: 3,
    pending_payments: "$1,199.99",
    status: "Client",
    last_project: "Consultoría de Diseño - Dormitorio Principal",
  },
  {
    id: 8,
    name: "Liam Blanco",
    email: "liam.blanco@gmail.com",
    phone: "+1-602-555-7777",
    projects: 1,
    invoices: 1,
    pending_payments: "$0.00",
    status: "Client",
    last_project: "Decoración de Sala",
  },
  {
    id: 9,
    name: "Isabella Reyes",
    email: "isabella.reyes@gmail.com",
    phone: "+1-702-555-8888",
    projects: 0,
    invoices: 0,
    pending_payments: "$0.00",
    status: "Potential Client",
    last_project: "N/A",
  },
];

export const MOCK_TASKS = [
  {
    id: 1,
    title: "Seleccionar muebles para la sala",
    checked: false,
    project_name: "Remodelación de Sala",
    users: [
      { id: 1, username: "Carlos Díaz" },
      { id: 2, username: "Laura Pérez" },
    ],
    comments: 4,
    phase: "Diseño Conceptual",
    dueDate: "Mar 30",
    priority: "Alta",
    status: "todos",
  },
  {
    id: 2,
    title: "Confirmar distribución de la cocina",
    checked: false,
    project_name: "Renovación de Cocina",
    users: [{ id: 3, username: "Ana González" }],
    comments: 2,
    phase: "Diseño Conceptual",
    dueDate: "Abr 2",
    priority: "Media",
    status: "todos",
  },
  {
    id: 3,
    title: "Seleccionar paleta de colores para el pasillo",
    checked: false,
    project_name: "Remodelación de Pasillo",
    users: [{ id: 4, username: "Miguel Torres" }],
    comments: 1,
    phase: "Diseño Esquemático",
    dueDate: "Abr 5",
    priority: "Alta",
    status: "todos",
  },
  {
    id: 4,
    title: "Seleccionar iluminación para el pasillo",
    checked: false,
    project_name: "Remodelación de Pasillo",
    subtasks: [
      { id: 31, title: "Consultar marcas disponibles", checked: true },
      { id: 32, title: "Finalizar opciones de producto", checked: false },
    ],
    users: [{ id: 5, username: "María Ríos" }],
    comments: 5,
    phase: "Diseño Esquemático",
    priority: "Alta",
    status: "todos",
  },
  {
    id: 5,
    title: "Definir materiales para el baño principal",
    checked: false,
    project_name: "Renovación de Baño Principal",
    subtasks: [
      { id: 33, title: "Seleccionar cerámicos y azulejos", checked: false },
      { id: 34, title: "Elegir grifería", checked: true },
    ],
    users: [{ id: 6, username: "Javier León" }],
    comments: 3,
    phase: "Desarrollo de Diseño",
    dueDate: "Abr 10",
    priority: "Alta",
    status: "inProgress",
  },
  {
    id: 6,
    title: "Revisión de opciones de muebles para la sala",
    checked: false,
    project_name: "Remodelación de Sala",
    users: [{ id: 1, username: "Carlos Díaz" }],
    comments: 3,
    phase: "Diseño Conceptual",
    dueDate: "Abr 1",
    priority: "Media",
    status: "inProgress",
  },
  {
    id: 7,
    title: "Revisar acabados de pared para el dormitorio",
    checked: false,
    project_name: "Rediseño de Dormitorio",
    users: [{ id: 7, username: "Carolina Muñoz" }],
    comments: 2,
    phase: "Diseño Esquemático",
    dueDate: "Abr 3",
    priority: "Alta",
    status: "inProgress",
  },
  {
    id: 8,
    title: "Diseñar esquema de colores para el dormitorio principal",
    checked: true,
    project_name: "Rediseño de Dormitorio Principal",
    users: [{ id: 5, username: "Laura Ortega" }],
    comments: 6,
    phase: "Desarrollo de Diseño",
    completionDate: "Mar 28",
    status: "inReview",
  },
  {
    id: 9,
    title: "Confirmar plano de distribución del salón",
    checked: true,
    project_name: "Remodelación de Sala",
    users: [{ id: 6, username: "Javier León" }],
    comments: 4,
    phase: "Diseño Conceptual",
    completionDate: "Mar 29",
    status: "completed",
  },
];

export const MOCK_PROJECTS_DATA = [
  {
    id: 1,
    projectName: "Remodelación Completa de Apartamento",
    client: {
      name: "Carlos López",
      email: "carlos.lopez@example.com",
      phone: "+1-555-111-2222",
    },
    status: "In Progress",
    startDate: "2024-02-01",
    endDate: "2024-08-30",
    providers: [
      { name: "Deco Walls", service: "Proveedor de Paredes y Acabados" },
      { name: "IlluminArt", service: "Proveedor de Iluminación" },
      { name: "CasaConfort", service: "Proveedor de Mobiliario" },
    ],
    invoice: {
      total: "$60,000",
      paid: "$25,000",
      due: "$35,000",
    },
    teamMembers: [
      { name: "María Fernández", role: "Diseñadora Principal" },
      { name: "José García", role: "Supervisor de Proyecto" },
      { name: "Luisa Torres", role: "Gerente de Proyecto" },
    ],
  },
  {
    id: 2,
    projectName: "Renovación de Sala de Estar",
    client: {
      name: "John Doe",
      email: "john.doe@example.com",
      phone: "+1-555-123-4567",
    },
    status: "Active",
    startDate: "2024-01-15",
    endDate: "2024-03-30",
    providers: [
      { name: "Modern Lighting Co.", service: "Proveedor de Iluminación" },
      { name: "Urban Furniture", service: "Proveedor de Mobiliario" },
    ],
    invoice: {
      total: "$10,000",
      paid: "$7,000",
      due: "$3,000",
    },
    teamMembers: [
      { name: "Alice Johnson", role: "Diseñadora Líder" },
      { name: "Bob Lee", role: "Gerente de Proyecto" },
    ],
  },
  {
    id: 3,
    projectName: "Renovación de Cocina",
    client: {
      name: "Jane Smith",
      email: "jane.smith@example.com",
      phone: "+1-555-987-6543",
    },
    status: "Completed",
    startDate: "2023-11-01",
    endDate: "2024-01-20",
    providers: [
      { name: "Elite Construction", service: "Contratista General" },
      {
        name: "HomeStyle Appliances",
        service: "Proveedor de Electrodomésticos",
      },
    ],
    invoice: {
      total: "$25,000",
      paid: "$25,000",
      due: "$0",
    },
    teamMembers: [
      { name: "Sarah Green", role: "Arquitecta" },
      { name: "John Doe", role: "Supervisor de Proyecto" },
    ],
  },
  {
    id: 4,
    projectName: "Diseño de Iluminación para Pasillo",
    client: {
      name: "Michael Brown",
      email: "michael.brown@example.com",
      phone: "+1-555-654-3210",
    },
    status: "Paused",
    startDate: "2024-04-01",
    endDate: "2024-05-10",
    providers: [
      { name: "Bright Lights Co.", service: "Proveedor de Iluminación" },
    ],
    invoice: {
      total: "$8,000",
      paid: "$0",
      due: "$8,000",
    },
    teamMembers: [{ name: "Alice Johnson", role: "Diseñadora Principal" }],
  },
  {
    id: 5,
    projectName: "Rediseño del Dormitorio Principal",
    client: {
      name: "Laura White",
      email: "laura.white@example.com",
      phone: "+1-555-765-4321",
    },
    status: "Active",
    startDate: "2024-02-10",
    endDate: "2024-04-01",
    providers: [
      { name: "SleepWell Mattresses", service: "Proveedor de Mobiliario" },
      { name: "Fresh Paint Co.", service: "Contratista de Pintura" },
    ],
    invoice: {
      total: "$12,500",
      paid: "$5,000",
      due: "$7,500",
    },
    teamMembers: [
      { name: "Bob Lee", role: "Gerente de Proyecto" },
      { name: "Jane Smith", role: "Decoradora de Interiores" },
    ],
  },
  {
    id: 6,
    projectName: "Renovación de Oficina en Casa",
    client: {
      name: "Emily Green",
      email: "emily.green@example.com",
      phone: "+1-555-321-8765",
    },
    status: "Planned",
    startDate: "2024-05-01",
    endDate: "2024-07-15",
    providers: [
      { name: "OfficeDesigns", service: "Proveedor de Mobiliario de Oficina" },
    ],
    invoice: {
      total: "$30,000",
      paid: "$10,000",
      due: "$20,000",
    },
    teamMembers: [
      { name: "Sarah Green", role: "Arquitecta" },
      { name: "Michael Brown", role: "Diseñador Líder" },
    ],
  },
];

export const MOCK_PROJECT_FILES = [
  {
    fileType: "pdf",
    fileName: "interactive_color_alette.pdf",
    creationDate: "2024-01-15T10:30:00Z",
    lastUpdatedDate: "2024-09-12T14:22:00Z",
    clientAccess: true,
    addToLanding: false,
  },
  {
    fileType: "embedded",
    fileName: "3d Model Tour",
    creationDate: "2023-11-05T08:12:00Z",
    lastUpdatedDate: "2024-03-22T16:45:00Z",
    url: "https://interiordesignstudio.com/3d-tour",
    clientAccess: true,
    addToLanding: true,
  },
  {
    fileType: "embedded",
    fileName: "Design Proposal",
    creationDate: "2023-06-10T09:45:00Z",
    lastUpdatedDate: "2023-12-01T11:35:00Z",
    url: "https://interiordesignstudio.com/color-palette",
    clientAccess: false,
    addToLanding: true,
  },
];

export const MOSK_TRADE_PORTAL = [
  {
    name: "Concreto Urbano",
    url: "https://co.konkretus.com/",
    image:
      "https://co.konkretus.com/cdn/shop/files/Mobiliario_urbano_1080x.jpg",
    tags: ["Concreto", "Diseño Moderno", "Mobiliario Urbano"],
    description:
      "Especialistas en mobiliario urbano de concreto con diseños modernos y duraderos, ideales para espacios exteriores y urbanos.",
  },
  {
    name: "Arte Sielu",
    url: "https://sieludesign.com/",
    image: "https://sieludesign.com/wp-content/uploads/2021/09/S4.jpg",
    tags: ["Artesanía", "Arte Moderno", "Diseño de Estilo de Vida"],
    description:
      "Estudio de diseño enfocado en el arte y el estilo de vida, ofreciendo piezas artesanales que reflejan creatividad y sofisticación.",
  },
  {
    name: "Vintarte",
    url: "https://www.vintario.co/",
    image:
      "https://static.wixstatic.com/media/6ad9a9_5d9d5b0ca93d4811955b3297ecc4494b~mv2.jpg/v1/fill/w_594,h_792,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/6ad9a9_5d9d5b0ca93d4811955b3297ecc4494b~mv2.jpg",
    tags: ["Vintage", "Decoración Artesanal", "Estilo Retro"],
    description:
      "Tienda de decoración que se especializa en piezas vintage y retro hechas a mano, perfectas para interiores con un toque nostálgico.",
  },
  {
    name: "Ilumina Sol",
    url: "https://solero.co/",
    image: "https://solero.co/wp-content/uploads/2020/10/GPH_6356-HDR-1-2.jpg",
    tags: [
      "Iluminación Exterior",
      "Mobiliario de Jardín",
      "Resistente al Clima",
    ],
    description:
      "Proveedor de soluciones de iluminación y mobiliario para exteriores, diseñado para resistir las condiciones climáticas y embellecer jardines y terrazas.",
  },
  {
    name: "Mármol & Lujo",
    url: "https://catemar.com.co/",
    image: "https://mmedia.misrevistas.com/odr/19298/1-23438.jpg",
    tags: ["Mármol", "Lujo", "Arquitectura de Alta Gama"],
    description:
      "Proveedor de mármol y superficies de lujo, con una selección exclusiva para proyectos arquitectónicos y de diseño de interiores de alta gama.",
  },
  {
    name: "Papel & Arte",
    url: "https://papelillo.com.co/",
    image:
      "https://papelillo.com.co/wp-content/uploads/2023/11/Papelillo-Armonia-Homepage-1.jpg",
    tags: ["Papel Hecho a Mano", "Artesanías Ecológicas", "Regalos Únicos"],
    description:
      "Productos artesanales de papel, regalos y decoraciones, creados con un enfoque sostenible y artesanal para detalles únicos.",
  },
];

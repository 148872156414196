import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler // Enable fill option
);

const FinanceCard = ({ data }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      y: {
        grid: {
          display: true,
          borderDash: [5, 5], // Dotted lines on x-axis
        },
      },
      x: {
        grid: {
          display: false, // Remove y-axis lines
        },
      },
    },
    elements: {
      line: {
        tension: 0.4, // Add smooth curves to the lines
      },
    },
  };

  const chartData = {
    labels: data.labels,
    datasets: [
      {
        label: "Cash Inflow",
        data: data.inflow,
        borderColor: "rgb(54, 162, 235)", // A stronger blue for better contrast
        backgroundColor: "rgba(54, 162, 235, 0.2)", // Light fill with the same blue
        fill: true, // Enable area fill
      },
      {
        label: "Cash Outflow",
        data: data.outflow,
        borderColor: "rgb(255, 159, 64)", // Strong orange for contrast
        backgroundColor: "rgba(255, 159, 64, 0.2)", // Light fill with orange
        fill: true, // Enable area fill
      },
    ],
  };

  return (
    <div className="rounded-lg overflow-hidden shadow-sm bg-white p-6 col-span-2">
      <h3 className="text-lg font-semibold text-gray-700 mb-4">Cash Flow</h3>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default FinanceCard;

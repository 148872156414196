import React from "react";
import Badge from "../../../../components/common/badges/Badge";

const InvoiceClient = ({ data }) => {
  if (!data) {
    return (
      <div className="text-center py-10 border rounded-md bg-gray-50">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-10 block mx-auto mb-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
          />
        </svg>
        No client associated to this expense.
      </div>
    );
  }

  const { name, email, phone, status } = data;

  return (
    <div className="space-y-1 p-4 bg-white border rounded-lg">
      {name && (
        <p>
          <strong>Name:</strong> {name}
        </p>
      )}
      {status && (
        <p>
          <strong>Status:</strong> <Badge status={status} />
        </p>
      )}
      {email && (
        <p>
          <strong>Email:</strong>{" "}
          <a href={`mailto:${email}`} className="text-blue-500 hover:underline">
            {email}
          </a>
        </p>
      )}
      {phone && (
        <p>
          <strong>Phone:</strong> +<a href={`tel:${phone}`}>{phone}</a>
        </p>
      )}
    </div>
  );
};

export default InvoiceClient;

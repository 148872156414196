import { useQuery } from "react-query";
import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}`;

// Fetch project details for public access
const fetchPublicProjectDetails = async ({ companyId, projectId }) => {
  if (!companyId || !projectId) {
    throw new Error("Company ID and Project ID are required.");
  }
  const response = await axios.get(
    `${API_URL}/public/project_details?company_id=${companyId}&project_id=${projectId}`
  );
  return response.data;
};

// React Query Hook for Public Project Details
export const usePublicProjectDetails = (companyId, projectId) => {
  return useQuery(
    ["publicProjectDetails", companyId, projectId],
    () => fetchPublicProjectDetails({ companyId, projectId }),
    {
      enabled: !!companyId && !!projectId,
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );
};

import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthProvider";

const ConfirmationSuccess = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-8 text-center">
          Account Confirmed
        </h2>
        <p className="text-center">
          Your account has been successfully confirmed. You can now{" "}
          <a href="/login" className="text-blue-500">
            log in
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default ConfirmationSuccess;

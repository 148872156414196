import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { useProjects } from "../../../../hooks/useProjects/useProjects";
import { useProviders } from "../../../../hooks/useProviders/useProviders";

const EditPurchaseOrder = ({
  updateExpense,
  expenseData,
  handleEditToggle,
  onExpenseUpdate,
}) => {
  const [formData, setFormData] = useState({
    description: expenseData?.description || "",
    amount: expenseData?.amount || 0,
    due_date: expenseData?.due_date ? expenseData.due_date.split("T")[0] : "",
    status: expenseData?.status || "Pending",
    location: expenseData?.location || "",
    payment_type: expenseData?.payment_type || "Credit Card",
    items: expenseData?.items || {},
    project_id: expenseData?.project_id || "",
    provider_id: expenseData?.provider_id || "",
  });

  const [error, setError] = useState({});

  const formatCurrency = (value) =>
    new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
    }).format(value);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleItemChange = (itemId, field, value) => {
    setFormData((prevData) => {
      const updatedItems = {
        ...prevData.items,
        [itemId]: {
          ...prevData.items[itemId],
          [field]:
            field === "value" ? Math.max(0, parseFloat(value) || 0) : value,
        },
      };
      const totalAmount = Object.values(updatedItems).reduce(
        (sum, item) => sum + (parseFloat(item.value) || 0),
        0
      );
      return { ...prevData, items: updatedItems, amount: totalAmount };
    });
  };

  const addItem = () => {
    const newItemId = `item-${Date.now()}`;
    setFormData((prevData) => ({
      ...prevData,
      items: { ...prevData.items, [newItemId]: { name: "", value: 0 } },
    }));
  };

  const removeItem = (itemId) => {
    setFormData((prevData) => {
      const updatedItems = { ...prevData.items };
      delete updatedItems[itemId];
      const totalAmount = Object.values(updatedItems).reduce(
        (sum, item) => sum + (parseFloat(item?.value) || 0),
        0
      );
      return { ...prevData, items: updatedItems, amount: totalAmount };
    });
  };

  const validateInputs = () => {
    const errors = {};
    if (!formData?.description.trim())
      errors.description = "Description is required.";
    if (formData.amount <= 0)
      errors.amount = "Total amount must be greater than 0.";
    Object.keys(formData.items).forEach((itemId) => {
      const item = formData.items[itemId];
      if (!item.name.trim())
        errors[`itemName${itemId}`] = "Item name is required.";
      if (!item.value || isNaN(item.value) || item.value <= 0)
        errors[`itemValue${itemId}`] = "Item value must be greater than 0.";
    });
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError({});
    if (!validateInputs()) return;

    try {
      const cleanedData = {
        ...formData,
        amount: parseFloat(formData.amount),
        items: Object.fromEntries(
          Object.entries(formData.items).map(([key, item]) => [
            key,
            { ...item, value: parseFloat(item.value) },
          ])
        ),
      };

      await updateExpense.mutateAsync({
        expenseId: expenseData.id,
        data: cleanedData,
      });
      onExpenseUpdate(cleanedData);
      handleEditToggle();
    } catch (error) {
      setError({ submit: "Failed to update expense. Please try again." });
    }
  };

  return (
    <div>
      {error.submit && (
        <div className="text-red-500 p-4 mb-4 bg-red-50 rounded">
          {error.submit}
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Description */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <input
            type="text"
            name="description"
            value={formData?.description}
            onChange={handleChange}
            required
            className="border p-2 w-full rounded max-w-md"
          />
          {error?.description && (
            <div className="text-red-500 text-sm mt-1">{error.description}</div>
          )}
        </div>

        {/* Due Date */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Due Date
          </label>
          <input
            type="date"
            name="due_date"
            value={formData?.due_date}
            onChange={handleChange}
            className="border p-2 w-full rounded max-w-md"
          />
        </div>

        {/* Status Dropdown */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Status
          </label>
          <select
            name="status"
            value={formData.status}
            onChange={handleChange}
            className="border p-2 w-full rounded max-w-md"
            required
          >
            <option value="Pending">Pending</option>
            <option value="Approved">Approved</option>
            <option value="In Process">In Process</option>
            <option value="Completed">Completed</option>
            <option value="Cancelled">Cancelled</option>
          </select>
        </div>

        {/* Payment Type */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Payment Type
          </label>
          <select
            name="payment_type"
            value={formData.payment_type}
            onChange={handleChange}
            className="border p-2 w-full rounded max-w-md"
          >
            <option value="Credit Card">Credit Card</option>
            <option value="Bank Transfer">Bank Transfer</option>
            <option value="Cash">Cash</option>
            <option value="Check">Check</option>
            <option value="Wire Transfer">Wire Transfer</option>
            <option value="Mobile Payment">Mobile Payment</option>
            <option value="Digital Wallet">Digital Wallet</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Location
          </label>
          <input
            type="text"
            name="location"
            placeholder="Expense location (optional)"
            value={formData.location}
            onChange={handleChange}
            className="border p-2 w-full rounded max-w-md"
          />
        </div>

        {/* Items */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Items
          </label>
          <table className="text-sm w-full max-w-md">
            <thead>
              <tr>
                <th className="border-b py-2">Item Name</th>
                <th className="border-b py-2">Item Value</th>
                <th className="border-b py-2"></th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(formData.items).map(([itemId, item]) => (
                <tr key={itemId}>
                  <td className="border-b py-2">
                    <input
                      type="text"
                      value={item.name}
                      onChange={(e) =>
                        handleItemChange(itemId, "name", e.target.value)
                      }
                      className="border p-2 w-full rounded"
                    />
                    {error[`itemName${itemId}`] && (
                      <div className="text-red-500 text-xs mt-1">
                        {error[`itemName${itemId}`]}
                      </div>
                    )}
                  </td>
                  <td className="border-b py-2">
                    <input
                      type="text"
                      placeholder="Item Value"
                      value={
                        item.value
                          ? new Intl.NumberFormat().format(item.value)
                          : ""
                      }
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(/,/g, "");
                        if (numericValue > 0) {
                          handleItemChange(itemId, "value", numericValue);
                        }
                      }}
                      className="border p-2 w-full rounded"
                    />
                    {error[`itemValue${itemId}`] && (
                      <div className="text-red-500 text-xs mt-1">
                        {error[`itemValue${itemId}`]}
                      </div>
                    )}
                  </td>
                  <td className="border-b py-2">
                    <button
                      type="button"
                      onClick={() => removeItem(itemId)}
                      className="text-red-500"
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            type="button"
            onClick={addItem}
            className="mt-2 text-blue-500"
          >
            Add Item
          </button>
        </div>

        {/* Total Amount */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Total Amount
          </label>
          <input
            type="text"
            value={formatCurrency(formData.amount)}
            readOnly
            disabled
            className="border p-2 w-full rounded max-w-md bg-gray-100 cursor-not-allowed"
          />
        </div>

        <div className="mt-4 flex space-x-4">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded"
            disabled={updateExpense.isLoading}
          >
            {updateExpense.isLoading ? "Saving..." : "Save"}
          </button>
          <button
            type="button"
            onClick={handleEditToggle}
            className="bg-gray-500 text-white px-4 py-2 rounded"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditPurchaseOrder;

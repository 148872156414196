import React from "react";
import TableHeader from "./TableHeader";
import {
  EmptyRows,
  ErrorRows,
  FileRow,
  InvoiceMinRow,
  InvoiceRow,
  LoadingRows,
  ProvidersTableRow,
  PurchaseOrdersRow,
  TableRows,
} from "./TableRows";

const TableContainer = ({
  hasCheckbox,
  columns,
  rowType = "default",
  data,
  loading,
  error,
  handleRowClick = () => {},
  children,
}) => {
  const tableRow = (rowType, i, val, handleRowClick, hasCheckbox) => {
    if (rowType === "providers") {
      return (
        <ProvidersTableRow
          key={i}
          data={val}
          onClick={handleRowClick}
          hasCheckbox={hasCheckbox}
        />
      );
    } else if (rowType === "purchase_orders") {
      return (
        <PurchaseOrdersRow
          key={i}
          data={val}
          onClick={handleRowClick}
          hasCheckbox={hasCheckbox}
        />
      );
    } else if (rowType === "invoices") {
      return (
        <InvoiceRow
          key={i}
          data={val}
          onClick={handleRowClick}
          hasCheckbox={hasCheckbox}
        />
      );
    } else if (rowType === "invoices-min") {
      return (
        <InvoiceMinRow
          key={i}
          data={val}
          onClick={handleRowClick}
          hasCheckbox={hasCheckbox}
        />
      );
    } else if (rowType === "files") {
      return (
        <FileRow
          key={i}
          data={val}
          onClick={handleRowClick}
          hasCheckbox={hasCheckbox}
        />
      );
    } else
      return (
        <TableRows
          key={i}
          data={val}
          onClick={handleRowClick}
          hasCheckbox={hasCheckbox}
        />
      );
  };
  return (
    <div className="relative overflow-x-auto">
      <table className="w-full text-sm text-left text-gray-500">
        <TableHeader hasCheckbox={hasCheckbox} columns={columns} />
        <tbody>
          {loading ? (
            <LoadingRows colSpan={columns?.length + 1 || 1} />
          ) : error ? (
            <ErrorRows colSpan={columns?.length + 1 || 1} />
          ) : data?.length === 0 ? (
            <EmptyRows colSpan={columns?.length + 1 || 1} />
          ) : (
            data?.map((val, i) =>
              tableRow(rowType, i, val, handleRowClick, hasCheckbox)
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableContainer;

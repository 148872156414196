import React from "react";

const ExpenseItems = ({ items = [] }) => {
  const totalAmount = items.reduce(
    (sum, item) => sum + (parseFloat(item.value) || 0),
    0
  );

  const formatCurrency = (value) =>
    new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
    }).format(value);

  return (
    <div className="bg-white rounded-lg shadow border p-6">
      <h2 className="text-lg font-semibold text-gray-900">Items</h2>

      {items && items?.length > 0 ? (
        <div className="mt-6 flow-root">
          <ul role="list" className="-my-6 divide-y divide-gray-200">
            {items.map((item, index) => (
              <li key={index} className="flex py-6">
                <div className="flex-1 flex flex-col">
                  <div className="flex justify-between text-base font-medium text-gray-900">
                    <p>{item.name || "Unnamed Item"}</p>
                    <p className="ml-4">{formatCurrency(item.value || 0)}</p>
                  </div>
                  {item.description && (
                    <p className="mt-1 text-sm text-gray-500">
                      {item.description}
                    </p>
                  )}
                  <div className="flex items-end justify-between text-sm mt-4">
                    {item.quantity && (
                      <p className="text-gray-500">Qty: {item.quantity}</p>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="mt-6 text-center text-gray-500">
          <p>No items have been added yet.</p>
        </div>
      )}

      {items.length > 0 && (
        <div className="border-t border-gray-200 mt-6 pt-4">
          <div className="flex justify-between text-lg font-medium text-gray-900 font-semibold">
            <p>Total:</p>
            <p>{formatCurrency(totalAmount)}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExpenseItems;

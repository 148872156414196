import React, { useEffect, useState } from "react";
import { MOSK_TRADE_PORTAL } from "../../../utils/mockData";

const TradePortal = () => {
  const [search, setSearch] = useState("");
  const [filteredProviders, setFilteredProviders] = useState(MOSK_TRADE_PORTAL);

  useEffect(() => {
    const result = MOSK_TRADE_PORTAL.filter(
      (provider) =>
        provider.name.toLowerCase().includes(search.toLowerCase()) ||
        provider.tags.some((tag) =>
          tag.toLowerCase().includes(search.toLowerCase())
        )
    );
    setFilteredProviders(result);
  }, [search]);

  return (
    <div>
      <h1 className="text-3xl font-bold text-center my-8">Trade Portal</h1>

      <input
        type="text"
        placeholder="Search providers by name or tag..."
        className="w-full p-3 mb-6 border rounded-lg shadow-sm"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {filteredProviders.map((provider) => (
          <a
            key={provider.name}
            href={provider.url}
            target="_blank"
            rel="noopener noreferrer"
            className="block bg-white rounded-lg shadow-md hover:shadow-xl transition overflow-hidden"
          >
            {/* Full-width Image */}
            <div className="relative">
              <img
                src={provider.image}
                alt={provider.name}
                className="w-full h-56 object-cover"
              />
            </div>

            {/* Provider Details */}
            <div className="p-6">
              <h2 className="text-xl font-semibold mb-2">{provider.name}</h2>
              <div className="flex flex-wrap gap-2">
                {provider.tags.map((tag) => (
                  <span
                    key={tag}
                    className="bg-gray-100 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default TradePortal;

import React from "react";
import { Link } from "react-router-dom";
import WhatsAppButton from "../../common/buttons/WhatsAppButton";

const LandingHeader = ({ isEarlyAccess }) => {
  return (
    <header>
      <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <Link to="/" className="flex items-center">
            <span className="self-center text-3xl font-semibold whitespace-nowrap">
              {"Plura.design"}
            </span>
          </Link>
          <div className="flex items-center lg:order-2">
            <Link
              to="/login"
              className="text-gray-800 hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 focus:outline-none"
            >
              Log in
            </Link>
            {isEarlyAccess ? (
              <WhatsAppButton />
            ) : (
              <Link
                to="/register"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 focus:outline-none"
              >
                Start Started Free
              </Link>
            )}
          </div>
        </div>
      </nav>
      <hr className="my-0 border-stale-200" />
    </header>
  );
};

export default LandingHeader;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import TableContainer from "../../../components/common/tables/TableContainer";
import DefaultCard from "../../../components/common/cards/DefaultCard";
import FiltersContainer from "../../../components/common/filters/FiltersContainer";
import Pagination from "../../../components/common/tables/Pagination";
import { PROVIDERS_TABLE_COLUMNS } from "../../../utils/tableColumns";
import { useProviders } from "../../../hooks/useProviders/useProviders";
import SlideOver from "../../../components/layout/SlideOver";
import ProviderDetails from "./ProviderDetails";

const Providers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const [sideOveOpen, setSideOverOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const navigate = useNavigate();

  const { data, isLoading, isError } = useProviders(currentPage, perPage);

  const handleAddProvider = () => {
    navigate("/partners/create");
  };

  const handlePageChange = (page) => setCurrentPage(page);

  const handleRowClick = (data) => {
    setSideOverOpen((prevState) => !prevState);
    setSelectedRow(data || {});
  };

  const providers = data?.providers || [];
  const totalItems = data?.pagination?.total_items || 0;

  return (
    <div>
      <PageHeadings
        title="Address Book"
        tags={[]}
        showCta={true}
        ctaLabel="Add Partner"
        handleCtaAction={handleAddProvider}
      />
      <DefaultCard>
        <FiltersContainer />
        <TableContainer
          hasCheckbox={true}
          columns={PROVIDERS_TABLE_COLUMNS}
          rowType="providers"
          data={providers}
          loading={isLoading}
          error={isError}
          handleRowClick={handleRowClick}
        />
        <Pagination
          currentPage={currentPage}
          totalCount={totalItems}
          pageSize={perPage}
          onPageChange={handlePageChange}
        />
      </DefaultCard>
      <SlideOver
        open={sideOveOpen}
        setOpen={setSideOverOpen}
        title="Partner Details"
      >
        <ProviderDetails
          data={selectedRow}
          closeSidebar={() => setSideOverOpen(false)}
        />
      </SlideOver>
    </div>
  );
};

export default Providers;

import React, { useContext, useEffect, useState } from "react";
import {
  useUsers,
  useUpdateUser,
  useDeleteUser,
  useCreateUser,
} from "../../../../hooks/useUsers";
import { AuthContext } from "../../../../context/AuthProvider";
import Pagination from "../../../../components/common/tables/Pagination";
import CreateUser from "./CreateUser";
import CardHeadings from "../../../../components/common/pageHeadings/CardHeadings";
import ErrorMessage from "../../../../components/common/empty/ErrorMessage";

const Users = () => {
  const { user } = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [newUser, setNewUser] = useState({ username: "", email: "", role: 0 });
  const [alert, setAlert] = useState(null);

  const perPage = 10;
  const { data, isLoading, isError } = useUsers(page, perPage);
  const updateUserMutation = useUpdateUser();
  const deleteUserMutation = useDeleteUser();
  const createUserMutation = useCreateUser();

  useEffect(() => {
    setNewUser({ username: "", email: "", role: 0 });
    setAlert(null);
  }, [showModal]);

  const handleUpdateUser = async (userId, updatedData) => {
    try {
      await updateUserMutation.mutateAsync({ userId, data: updatedData });
      setAlert({ type: "success", message: "User updated successfully!" });
    } catch (error) {
      setAlert({ type: "error", message: "Error updating user!" });
    }
  };

  const handleDeleteUser = async (userId) => {
    if (user.id === userId) {
      alert("You cannot delete yourself.");
      return;
    }
    try {
      await deleteUserMutation.mutateAsync({ userId });
      setAlert({ type: "success", message: "User deleted successfully!" });
    } catch (error) {
      setAlert({ type: "error", message: "Error deleting user!" });
    }
  };

  const handleCreateUser = async (event) => {
    event.preventDefault();
    setAlert(null);
    try {
      await createUserMutation.mutateAsync(newUser);
      setShowModal(false);
      setNewUser({ username: "", email: "", role: 0 });
      setAlert({ type: "success", message: "User created successfully!" });
    } catch (error) {
      setAlert({
        type: "error",
        message: error.response?.data?.msg || "An error occurred.",
      });
    }
  };

  const handleClickOutside = (event) => {
    if (event.target.id === "modal-overlay") {
      setShowModal(false);
    }
  };

  const handleCloseAlert = () => {
    setAlert(null);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <ErrorMessage />;
  }

  return (
    <div
      className={`overflow-hidden place-items-start p-4 bg-white rounded-md border border-gray-200 ${
        showModal ? "overlay" : ""
      }`}
    >
      {alert && (
        <div
          className={`block p-4 mb-4 text-sm rounded-lg ${
            alert.type === "success"
              ? "text-green-800 bg-green-50"
              : "text-red-800 bg-red-50"
          }`}
          role="alert"
        >
          {alert.message}
          <button
            onClick={handleCloseAlert}
            className="float-right text-sm text-gray-700"
          >
            &times;
          </button>
        </div>
      )}
      <div className="w-full min-w-0">
        <div className="flex justify-between items-center mb-4">
          <CardHeadings
            title={"User Management"}
            description={"Configure Roles"}
          />
          <button
            className="px-4 py-2 bg-blue-600 text-white rounded shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            onClick={() => setShowModal(true)}
          >
            Create User
          </button>
        </div>
        <div className="flow-root">
          <div className="mt-6 -mx-6 overflow-x-auto whitespace-nowrap sm:-mx-8 lg:-mx-12">
            <div className="inline-block min-w-full align-middle sm:px-8 lg:px-12">
              <table className="min-w-full text-left text-sm">
                <thead className="text-gray-500">
                  <tr>
                    <th className="border-b border-gray-200 px-4 py-2 font-medium sm:first:pl-2 sm:last:pr-2">
                      Name
                    </th>
                    <th className="border-b border-gray-200 px-4 py-2 font-medium sm:first:pl-2 sm:last:pr-2">
                      Email
                    </th>
                    <th className="border-b border-gray-200 px-4 py-2 font-medium sm:first:pl-2 sm:last:pr-2">
                      Role
                    </th>
                    <th className="border-b border-gray-200 px-4 py-2 font-medium sm:first:pl-2 sm:last:pr-2">
                      Confirmed
                    </th>
                    <th className="relative w-0 border-b border-gray-200 px-4 py-2 font-medium sm:first:pl-2 sm:last:pr-2">
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {data.users.map((value) => (
                    <tr key={value.id}>
                      <td className="px-4 py-4 text-gray-900 sm:first:pl-2 sm:last:pr-2">
                        {value.username}
                      </td>
                      <td className="px-4 py-4 text-gray-900 sm:first:pl-2 sm:last:pr-2">
                        {value.email}
                      </td>
                      <td className="px-4 py-4 text-gray-900 sm:first:pl-2 sm:last:pr-2">
                        <select
                          value={value.role}
                          onChange={(e) =>
                            handleUpdateUser(value.id, {
                              role: parseInt(e.target.value, 10),
                            })
                          }
                          disabled={user?.id === value?.id || user?.role !== 0}
                          className={`border p-1 rounded ${
                            user?.id === value?.id || user?.role !== 0
                              ? "bg-gray-100 border-gray-200"
                              : ""
                          }`}
                        >
                          <option value="0">Admin</option>
                          <option value="1">Manager</option>
                          <option value="2">Designer</option>
                          <option value="3">View only</option>
                        </select>
                      </td>
                      <td className="px-4 py-4 text-gray-900 sm:first:pl-2 sm:last:pr-2">
                        <span
                          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            value.confirmed
                              ? "bg-green-100 text-green-800"
                              : "bg-yellow-100 text-yellow-800"
                          }`}
                        >
                          {value.confirmed ? "Confirmed" : "Pending"}
                        </span>
                      </td>
                      <td className="px-4 py-4 text-gray-900 sm:first:pl-2 sm:last:pr-2">
                        {user?.id !== value?.id && user?.role === 0 ? (
                          <button
                            onClick={() => handleDeleteUser(value.id)}
                            className={`flex rounded bg-red-100 text-red-900 block w-full text-left px-2 py-1 text-sm`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-4 h-4 mr-1 my-auto"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                            Delete
                          </button>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {data?.pages > 1 && (
          <Pagination data={data} page={page} setPage={setPage} />
        )}
      </div>
      <CreateUser
        showModal={showModal}
        setShowModal={setShowModal}
        handleClickOutside={handleClickOutside}
        handleCreateUser={handleCreateUser}
        newUser={newUser}
        setNewUser={setNewUser}
        createUserMutation={createUserMutation}
      />
    </div>
  );
};

export default Users;

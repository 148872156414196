import { useQuery, useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../context/AuthProvider";
import axiosInstance from "./axiosInstance";

const API_URL = `${process.env.REACT_APP_API_URL}`;

const setPassword = async ({ token, password }) => {
  const response = await axiosInstance.post(
    `${API_URL}/set-password/${token}`,
    {
      password,
    }
  );
  return response.data;
};

export const useSetPassword = () => {
  return useMutation(setPassword);
};

const fetchUsers = async (page, perPage, token) => {
  const response = await axiosInstance.get(
    `${API_URL}/users?page=${page}&per_page=${perPage}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const fetchUser = async (userId, token) => {
  const response = await axiosInstance.get(`${API_URL}/users/${userId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const createUser = async ({ data, token }) => {
  const response = await axiosInstance.post(`${API_URL}/users`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const useCreateUser = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation((data) => createUser({ data, token: user?.token }), {
    onSuccess: () => {
      queryClient.invalidateQueries("users");
    },
  });
};

const updateUser = async ({ userId, data, token }) => {
  const response = await axiosInstance.put(`${API_URL}/users/${userId}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const deleteUser = async ({ userId, token }) => {
  await axiosInstance.delete(`${API_URL}/users/${userId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const useUsers = (page, perPage) => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["users", page, perPage],
    () => fetchUsers(page, perPage, user?.token),
    {
      enabled: !!user?.token,
    }
  );
};

export const useUser = (userId) => {
  const { user } = useContext(AuthContext);
  return useQuery(["user", userId], () => fetchUser(userId, user?.token), {
    enabled: !!user?.token,
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation(
    ({ userId, data }) => updateUser({ userId, data, token: user?.token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
      },
    }
  );
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation(
    ({ userId }) => deleteUser({ userId, token: user?.token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
      },
    }
  );
};

const forgotPassword = async (email) => {
  const response = await axiosInstance.post(`${API_URL}/auth/forgot-password`, {
    email,
  });
  return response.data;
};

export const useForgotPassword = () => {
  return useMutation(forgotPassword);
};

const resetPassword = async ({ token, password }) => {
  const response = await axiosInstance.post(
    `${API_URL}/auth/reset-password/${token}`,
    {
      password,
    }
  );
  return response.data;
};

export const useResetPassword = () => {
  return useMutation(resetPassword);
};

import React from "react";

const STATUS_COLORS = {
  Lead: "bg-gray-100 text-gray-800",
  Prospect: "bg-blue-100 text-blue-800",
  "Potential Client": "bg-yellow-100 text-yellow-800",
  "Proposal Sent": "bg-purple-100 text-purple-800",
  Negotiation: "bg-orange-100 text-orange-800",
  "Active Client": "bg-green-100 text-green-800",
  "In Progress": "bg-teal-100 text-teal-800",
  "Repeat Client": "bg-indigo-100 text-indigo-800",
  "High Priority": "bg-red-100 text-red-800",
  "On Hold": "bg-gray-200 text-gray-700",
  "Pending Payment": "bg-yellow-200 text-yellow-900",
  Inactive: "bg-gray-300 text-gray-700",
  Churned: "bg-red-200 text-red-800",
  "Past Client": "bg-gray-400 text-gray-900",
  "VIP Client": "bg-purple-200 text-purple-900",
};

const Badge = ({ status }) => {
  const colorClass = STATUS_COLORS[status] || "bg-gray-100 text-gray-800";

  return (
    <span className={`text-xs font-medium px-2.5 py-0.5 rounded ${colorClass}`}>
      {status}
    </span>
  );
};

export default Badge;

import React from "react";
import { Link } from "react-router-dom";

const ProjectHeader = ({ data }) => {
  return (
    <header className="bg-white border border-b-gray-100">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          {data?.webiste ? (
            <Link to={data?.webiste} target="_blank" role="button">
              <span className="sr-only">{data?.name}</span>
              <h1 className="font-bold text-xl">{data?.name}</h1>
            </Link>
          ) : (
            <>
              <span className="sr-only">{data?.name}</span>
              <h1 className="font-bold text-xl">{data?.name}</h1>
            </>
          )}
        </div>
        <div className="flex flex-1 justify-end"></div>
      </nav>
    </header>
  );
};

export default ProjectHeader;

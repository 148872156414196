import React from "react";
import { getInitials } from "../../../utils/helpers";
import { Link } from "react-router-dom";

const ProjectCard = ({ data }) => {
  return (
    <Link
      to={`/projects/${data?.id}`}
      className="flex flex-col rounded-lg border border-gray-200 bg-white shadow-sm hover:shadow-lg"
      role="button"
    >
      <div className="p-6 flex-grow">
        {/* Project Status and Actions */}
        <div className="mb-4 flex items-center justify-between gap-4">
          <span
            className={`me-2 rounded px-2.5 py-0.5 text-xs font-medium ${
              data?.status === "Completed"
                ? "bg-green-100 text-green-800"
                : data?.status === "Paused"
                ? "bg-yellow-100 text-yellow-800"
                : data?.status === "Active" || data?.status === "In Progress"
                ? "bg-blue-100 text-blue-800"
                : data?.status === "Cancelled"
                ? "bg-red-100 text-red-800"
                : "bg-gray-100 text-gray-800"
            }`}
          >
            {data?.status}
          </span>
          <div className="flex -space-x-3">
            {data?.teamMembers?.map((member, index) => (
              <div
                key={index}
                className="h-6 w-6 rounded-full bg-blue-600 text-white flex items-center justify-center border border-white"
                style={{ fontSize: "10px" }}
                title={member.name}
              >
                {getInitials(member?.name)}
              </div>
            ))}
          </div>
        </div>

        {/* Project Title */}
        <h3 className="text-lg font-semibold leading-tight text-gray-900">
          {data?.name}
        </h3>

        {/* Client Information */}
        {data?.client && (
          <div>
            <p className="text-sm font-medium text-gray-600">
              Client: {data?.client?.name}
            </p>
          </div>
        )}

        {/* External Providers */}
        {data?.providers && data?.providers?.length > 0 && (
          <div className="mt-4">
            <h4 className="text-sm font-medium text-gray-600">Providers:</h4>
            <ul className="text-sm text-gray-500">
              <li>
                {data?.providers[0]?.name}
                {data?.providers?.length > 1 &&
                  `, +${data?.providers?.length - 1}`}
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className="bg-gray-50 border-t rounded-b-lg px-6 py-3">
        <p className="text-sm font-medium text-gray-600 flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
            />
          </svg>
          {data?.creation_date
            ? new Date(data.creation_date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            : "No date available"}
        </p>
      </div>
    </Link>
  );
};

export default ProjectCard;

import React, { useState } from "react";
import {
  useComments,
  useCreateComment,
  useDeleteComment,
  useUpdateComment,
} from "../../../../hooks/useClients/useClients";

const ClientComments = ({ clientId }) => {
  const [newCommentText, setNewCommentText] = useState("");
  const [editingComment, setEditingComment] = useState(null);

  const { data: comments, isLoading, isError } = useComments(clientId);
  const createComment = useCreateComment(clientId);
  const updateComment = useUpdateComment(clientId);
  const deleteComment = useDeleteComment(clientId);

  const handleCreateComment = async () => {
    if (!newCommentText.trim()) return;
    createComment.mutate(
      { text: newCommentText },
      {
        onSuccess: () => {
          setNewCommentText("");
        },
      }
    );
  };

  const handleUpdateComment = async () => {
    if (!editingComment || !editingComment.text.trim()) return;
    updateComment.mutate({
      noteId: editingComment.id,
      data: { text: editingComment.text },
    });
    setEditingComment(null);
  };

  const handleDeleteComment = (commentId) => {
    deleteComment.mutate(commentId);
  };

  if (isLoading) return <p>Loading comments...</p>;

  return (
    <div>
      <h2 className="font-semibold mb-4">Notes:</h2>

      <div className="space-y-4">
        {comments.map((comment) => (
          <div key={comment.id}>
            <div className="flex flex-col w-full p-4 bg-gray-100 rounded-lg">
              {editingComment?.id === comment.id ? (
                <textarea
                  className="w-full p-2 mt-2 text-sm border rounded"
                  value={editingComment.text}
                  onChange={(e) =>
                    setEditingComment({
                      ...editingComment,
                      text: e.target.value,
                    })
                  }
                />
              ) : (
                <>
                  <span className="text-sm font-normal text-gray-500">
                    {comment.updated_at &&
                      `Last Updated: ${new Date(
                        comment?.updated_at
                      ).toLocaleTimeString()}`}
                  </span>
                  <p className="text-sm font-normal py-2.5 text-gray-900">
                    {comment.text}
                  </p>
                </>
              )}
              {/* Actions */}
              <div className="flex space-x-2 mt-2">
                {editingComment?.id === comment.id ? (
                  <>
                    <button
                      onClick={handleUpdateComment}
                      className="text-sm text-blue-600"
                    >
                      Save
                    </button>
                    <button
                      onClick={() => setEditingComment(null)}
                      className="text-sm text-gray-600"
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => setEditingComment(comment)}
                      className="text-sm text-blue-600"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeleteComment(comment.id)}
                      className="text-sm text-red-600"
                    >
                      Delete
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-6">
        <textarea
          className="w-full p-2 text-sm border rounded"
          placeholder="Add a comment..."
          value={newCommentText}
          onChange={(e) => setNewCommentText(e.target.value)}
        />
        <button
          onClick={handleCreateComment}
          className="mt-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded hover:bg-blue-700"
          disabled={!newCommentText.trim()}
        >
          Add Comment
        </button>
      </div>
    </div>
  );
};

export default ClientComments;

import { useQuery, useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthProvider";
import axiosInstance from "../axiosInstance";

const API_URL = `${process.env.REACT_APP_API_URL}`;

const fetchClients = async (page, perPage, token) => {
  const response = await axiosInstance.get(
    `${API_URL}/clients?page=${page}&per_page=${perPage}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const fetchClient = async (clientId, token) => {
  const response = await axiosInstance.get(`${API_URL}/clients/${clientId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const createClient = async ({ data, token }) => {
  const response = await axiosInstance.post(`${API_URL}/clients`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const updateClient = async ({ clientId, data, token }) => {
  const response = await axiosInstance.put(
    `${API_URL}/clients/${clientId}`,
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const deleteClient = async ({ clientId, token }) => {
  await axiosInstance.delete(`${API_URL}/clients/${clientId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const useClients = (page = 1, perPage = 10) => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["clients", page, perPage],
    () => fetchClients(page, perPage, user?.token),
    { enabled: !!user?.token }
  );
};

export const useClient = (clientId) => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["client", clientId],
    () => fetchClient(clientId, user?.token),
    {
      enabled: !!user?.token,
    }
  );
};

export const useCreateClient = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation((data) => createClient({ data, token: user?.token }), {
    onSuccess: () => {
      queryClient.invalidateQueries("clients");
    },
  });
};

export const useUpdateClient = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation(
    ({ clientId, data }) =>
      updateClient({ clientId, data, token: user?.token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("clients");
      },
    }
  );
};

export const useDeleteClient = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation(
    ({ clientId }) => deleteClient({ clientId, token: user?.token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("clients");
      },
    }
  );
};

// Comments CRUD

const fetchComments = async ({ clientId, token }) => {
  const response = await axiosInstance.get(
    `${API_URL}/clients/${clientId}/notes`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const createComment = async ({ clientId, data, token }) => {
  const response = await axiosInstance.post(
    `${API_URL}/clients/${clientId}/notes`,
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const updateComment = async ({ clientId, noteId, data, token }) => {
  const response = await axiosInstance.put(
    `${API_URL}/clients/${clientId}/notes/${noteId}`,
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const deleteComment = async ({ clientId, noteId, token }) => {
  await axiosInstance.delete(`${API_URL}/clients/${clientId}/notes/${noteId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// Fetch comments for a specific client
export const useComments = (clientId) => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["comments", clientId],
    () => fetchComments({ clientId, token: user?.token }),
    {
      enabled: !!user?.token,
    }
  );
};

// Create a new comment for a specific client
export const useCreateComment = (clientId) => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation(
    (data) => createComment({ clientId, data, token: user?.token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["comments", clientId]);
      },
    }
  );
};

// Update an existing comment for a specific client
export const useUpdateComment = (clientId) => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation(
    ({ noteId, data }) =>
      updateComment({ clientId, noteId, data, token: user?.token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["comments", clientId]);
      },
    }
  );
};

// Delete a comment for a specific client
export const useDeleteComment = (clientId) => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation(
    (noteId) => deleteComment({ clientId, noteId, token: user?.token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["comments", clientId]);
      },
    }
  );
};

import React, { useState, useEffect, useMemo, useContext } from "react";
import CardHeadings from "../../../../components/common/pageHeadings/CardHeadings";
import AsyncSelect from "react-select/async";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useUpdateProject } from "../../../../hooks/useProjects/useProjects";
import { useClients } from "../../../../hooks/useClients/useClients";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../context/AuthProvider";
import { generateEncryptedLink } from "../../../../utils/cryptoUtils";

// TODO: Fix assign client and edit client bug.

const ProjectSettings = ({ data }) => {
  const { user } = useContext(AuthContext);
  const [copySuccess, setCopySuccess] = useState(false);

  const [formData, setFormData] = useState({
    name: data?.name || "",
    description: data?.description || "",
    status: data?.status || "Planned",
    address: data?.address || "",
    country: data?.country || "",
    city: data?.city || "",
    portal_enabled: data?.portal_enabled || false,
    client_id:
      data?.clients && data.clients.length > 0 ? data.clients[0].id : "",
  });
  const [sharableLink] = useState(
    generateEncryptedLink(user?.company_id, data?.id)
  );
  const [error, setError] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const queryClient = useQueryClient();
  const { mutate: updateProject, isLoading } = useUpdateProject();
  const { data: clientsData, isLoading: clientsLoading } = useClients(1, 100);

  // Memoize client options to prevent unnecessary re-renders
  const clientOptions = useMemo(
    () =>
      clientsData?.clients.map((client) => ({
        value: client.id,
        label: client.name,
      })) || [],
    [clientsData]
  );

  // Pre-select the client if one is associated
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    if (formData.client_id) {
      const client = clientOptions.find(
        (option) => option.value === formData.client_id
      );
      if (client && client !== selectedClient) {
        setSelectedClient(client);
      }
    }
  }, [formData.client_id, clientOptions, selectedClient]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleTogglePortalEnabled = () => {
    setFormData((prevData) => ({
      ...prevData,
      portal_enabled: !prevData.portal_enabled,
    }));
  };

  const handleCountryChange = (country) => {
    setFormData((prevData) => ({ ...prevData, country, city: "" }));
  };

  const handleCityChange = (city) => {
    setFormData((prevData) => ({ ...prevData, city }));
  };

  const handleClientChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      client_id: selectedOption ? selectedOption.value : "",
    }));
    setSelectedClient(selectedOption);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.name) {
      setError({ name: "Project name is required." });
      return;
    }

    updateProject(
      { projectId: data.id, data: formData },
      {
        onSuccess: () => {
          setSuccessMessage("Project updated successfully!");
          queryClient.invalidateQueries(["project", data.id]);
          setTimeout(() => setSuccessMessage(""), 3000);
        },
        onError: (error) => {
          setError({ submit: error.message || "Failed to update project." });
        },
      }
    );
  };

  return (
    <div>
      <div className="px-4 sm:px-0 bg-white rounded-lg">
        <CardHeadings
          title="Project Configuration"
          description="Details and settings for this project."
        />
      </div>
      <div className="bg-white p-4 border border-gray-100">
        {successMessage && (
          <div className="mb-4 p-4 text-green-700 bg-green-100 rounded">
            {successMessage}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Project Name */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Project Name
            </label>
            <input
              type="text"
              name="name"
              placeholder="Project Name"
              value={formData.name}
              onChange={handleChange}
              required
              className="border p-2 w-full rounded max-w-md"
            />
            {error.name && (
              <div className="text-red-500 text-sm mt-1">{error.name}</div>
            )}
          </div>

          {/* Status */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Status
            </label>
            <select
              name="status"
              value={formData.status}
              onChange={handleChange}
              className="border p-2 w-full rounded max-w-md"
              required
            >
              <option value="Planned">Planned</option>
              <option value="In Progress">In Progress</option>
              <option value="Active">Active</option>
              <option value="Completed">Completed</option>
              <option value="Paused">Paused</option>
              <option value="Cancelled">Cancelled</option>
            </select>
            {error.status && (
              <div className="text-red-500 text-sm mt-1">{error.status}</div>
            )}
          </div>

          {/* Description */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              name="description"
              placeholder="Project Description"
              value={formData.description}
              onChange={handleChange}
              className="border p-2 w-full rounded max-w-md"
              rows="4"
            />
          </div>

          {/* Client Selection */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Associate Client
            </label>
            <AsyncSelect
              cacheOptions
              defaultOptions={clientOptions}
              isLoading={clientsLoading}
              value={selectedClient}
              loadOptions={(inputValue, callback) => {
                const filteredOptions = clientOptions.filter((option) =>
                  option.label.toLowerCase().includes(inputValue.toLowerCase())
                );
                callback(filteredOptions);
              }}
              onChange={handleClientChange}
              placeholder="Select a client"
              isClearable
              className="max-w-md"
            />
          </div>

          {/* Address */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Address
            </label>
            <input
              type="text"
              name="address"
              placeholder="Project Address"
              value={formData.address}
              onChange={handleChange}
              className="border p-2 w-full rounded max-w-md"
            />
          </div>

          {/* Country */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Country
            </label>
            <CountryDropdown
              value={formData.country}
              onChange={handleCountryChange}
              classes="border p-2 w-full rounded max-w-md"
            />
          </div>

          {/* City */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              City
            </label>
            <RegionDropdown
              country={formData.country}
              value={formData.city}
              onChange={handleCityChange}
              classes="border p-2 w-full rounded max-w-md"
            />
          </div>

          {/* Portal Enabled Switch */}
          <div className="flex items-center mt-4">
            <label className="text-sm font-medium text-gray-700 mr-4">
              Portal Enabled
            </label>
            <button
              type="button"
              onClick={handleTogglePortalEnabled}
              className={`w-10 h-6 flex items-center rounded-full p-1 ${
                formData.portal_enabled ? "bg-blue-500" : "bg-gray-300"
              }`}
            >
              <div
                className={`h-4 w-4 rounded-full bg-white transform ${
                  formData.portal_enabled ? "translate-x-4" : "translate-x-0"
                }`}
              />
            </button>
          </div>

          {data?.portal_enabled && (
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700">
                Shareable Link
              </label>
              <div className="flex items-center bg-gray-50 p-2 rounded border border-gray-200  max-w-md">
                {/* Display shortened link */}
                <Link
                  to={sharableLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline truncate"
                  title={window.location.origin + sharableLink}
                >
                  {`${window.location.origin}${
                    sharableLink.length > 30
                      ? `${sharableLink.substring(0, 30)}...`
                      : sharableLink
                  }`}
                </Link>
                {/* Copy button */}
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    navigator.clipboard.writeText(
                      window.location.origin + sharableLink
                    );
                    setCopySuccess(true); // Show success message
                    setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
                  }}
                  className="ml-4 flex items-center text-gray-500 px-4 py-1 rounded hover:bg-gray-100 focus:outline-none"
                >
                  {copySuccess ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"
                        />
                      </svg>
                    </>
                  )}
                </button>
              </div>
            </div>
          )}

          {/* Submit Button */}
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded"
            disabled={isLoading}
          >
            {isLoading ? "Saving..." : "Save Changes"}
          </button>
          {error.submit && (
            <div className="text-red-500 text-sm mt-1">{error.submit}</div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ProjectSettings;

import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthProvider";
import PageHeadings from "../common/pageHeadings/PageHeadings";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import MainStats from "./dashboardAnalytics/MainStats";
import FinanceCard from "./dashboardAnalytics/FinanceCard";
import ProjectStatusCard from "./dashboardAnalytics/ProjectStatusCard";
import TaskStatusCard from "./dashboardAnalytics/TaskStatusCard";
import TodaysTasksCard from "./dashboardAnalytics/TodaysTasksCard";
import PendingInvoicesCard from "./dashboardAnalytics/PendingInvoicesCard";
import AlertProjectsCard from "./dashboardAnalytics/AlertProjectsCard";

// Registering Chart.js modules
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DashboardContent = () => {
  const { user } = useContext(AuthContext);

  return (
    <div>
      <PageHeadings
        title={`Welcome ${user?.username}!`}
        tags={[]}
        showCta={false}
      />
      <Analytics />
    </div>
  );
};

export default DashboardContent;

const Analytics = () => {
  const financeData = {
    labels: ["January", "February", "March", "April", "May"],
    inflow: [5000, 3000, 4000, 6000, 7000],
    outflow: [3000, 2000, 3000, 4000, 5000],
  };

  const projectStatusData = {
    completed: 10,
    inProgress: 5,
    pending: 3,
  };

  const taskStatusData = {
    todos: 8,
    inProgress: 12,
    inReview: 5,
    done: 20,
  };

  // Mock data for today's tasks (including overdue tasks)
  const tasks = [
    {
      title: "Select furniture",
      project_name: "Living Room",
      dueDate: "Today",
    },
    { title: "Finalize layout", project_name: "Kitchen", dueDate: "Today" },
    { title: "Review lighting", project_name: "Hallway", dueDate: "Overdue" },
  ];

  // Mock data for pending invoices
  const invoices = [
    { id: "INV-001", client: "John Doe", amount: 450, dueDate: "Oct 20" },
    { id: "INV-002", client: "Jane Smith", amount: 300, dueDate: "Oct 25" },
    { id: "INV-003", client: "Acme Corp", amount: 1200, dueDate: "Nov 1" },
  ];
  // Mock data for projects not updated recently
  const projects = [
    {
      name: "Living Room Renovation",
      lastUpdated: "Sept 15",
      status: "Pending",
    },
    { name: "Kitchen Overhaul", lastUpdated: "Sept 18", status: "In Progress" },
    { name: "Bedroom Makeover", lastUpdated: "Sept 20", status: "Pending" },
  ];

  return (
    <div className="grid gap-6 grid-cols-1 lg:grid-cols-2 xl:grid-cols-4">
      <MainStats />
      <FinanceCard data={financeData} />
      <PendingInvoicesCard invoices={invoices} />
      <TaskStatusCard data={taskStatusData} />
      <ProjectStatusCard data={projectStatusData} />
      <TodaysTasksCard tasks={tasks} />
      <AlertProjectsCard projects={projects} />
    </div>
  );
};

// context/AuthProvider.js
import React, { createContext, useState, useEffect } from "react";
import {
  getCurrentUser,
  setCurrentUser,
  removeCurrentUser,
} from "../services/auth";
import { jwtDecode } from "jwt-decode";
import axiosInstance from "../hooks/axiosInstance";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [authInitialized, setAuthInitialized] = useState(false);

  useEffect(() => {
    const storedUser = getCurrentUser();
    if (storedUser) {
      try {
        const decodedToken = jwtDecode(storedUser.access_token);
        if (decodedToken.exp * 1000 > Date.now()) {
          setUser({ ...decodedToken, token: storedUser.access_token });
          axiosInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${storedUser.access_token}`;
        } else {
          removeCurrentUser();
        }
      } catch (error) {
        removeCurrentUser();
      }
    }
    setAuthInitialized(true);
  }, []);

  const login = (userData) => {
    setCurrentUser(userData);
    const decodedToken = jwtDecode(userData.access_token);
    setUser({ ...decodedToken, token: userData.access_token });
    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${userData.access_token}`;
  };

  const logout = () => {
    removeCurrentUser();
    setUser(null);
    delete axiosInstance.defaults.headers.common["Authorization"];
    window.location.href = "/login";
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, authInitialized }}>
      {children}
    </AuthContext.Provider>
  );
};

import React from "react";

const TableHeader = ({ hasCheckbox = false, columns = [] }) => {
  return (
    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
      <tr>
        {hasCheckbox && (
          <th scope="col" className="p-4">
            <div className="flex items-center">
              <input
                id="checkbox-all"
                type="checkbox"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
              />
              <label htmlFor="checkbox-all" className="sr-only">
                checkbox
              </label>
            </div>
          </th>
        )}
        {columns?.map((val, i) => (
          <th key={i} scope="col" className="px-6 py-3">
            {val}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;

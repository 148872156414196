import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Get the current year

  return (
    <footer className="mx-auto w-full max-w-2xl space-y-10 pb-16 lg:max-w-5xl">
      <div className="flex flex-col items-center justify-between gap-5 border-t border-zinc-900/5 pt-8 sm:flex-row dark:border-white/5">
        <p className="text-xs text-zinc-600 dark:text-zinc-400">
          © Copyright {currentYear} Plura.design. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;

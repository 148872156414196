import { useQuery, useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthProvider";
import axiosInstance from "../axiosInstance";

const API_URL = `${process.env.REACT_APP_API_URL}`;

// Fetch all client files for a specific project
const fetchFilesByProject = async (projectId, token) => {
  const response = await axiosInstance.get(
    `${API_URL}/projects/${projectId}/client_files`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

// Fetch a specific client file by ID
const fetchClientFile = async (fileId, token) => {
  const response = await axiosInstance.get(
    `${API_URL}/client_files/${fileId}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

// Create a new client file
const createClientFile = async ({ data, token }) => {
  const response = await axiosInstance.post(`${API_URL}/client_files`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

// Update an existing client file
const updateClientFile = async ({ fileId, data, token }) => {
  const response = await axiosInstance.put(
    `${API_URL}/client_files/${fileId}`,
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

// Delete a client file
const deleteClientFile = async ({ fileId, token }) => {
  await axiosInstance.delete(`${API_URL}/client_files/${fileId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// React Query hooks

// Fetch client files by project
export const useFilesByProject = (projectId) => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["filesByProject", projectId],
    () => fetchFilesByProject(projectId, user?.token),
    {
      enabled: !!user?.token && !!projectId,
    }
  );
};

// Fetch a single client file
export const useClientFile = (fileId) => {
  const { user } = useContext(AuthContext);
  return useQuery(
    ["clientFile", fileId],
    () => fetchClientFile(fileId, user?.token),
    {
      enabled: !!user?.token && !!fileId,
    }
  );
};

// Create a new client file
export const useCreateClientFile = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation((data) => createClientFile({ data, token: user?.token }), {
    onSuccess: () => {
      queryClient.invalidateQueries("filesByProject");
    },
  });
};

// Update an existing client file
export const useUpdateClientFile = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation(
    ({ fileId, data }) =>
      updateClientFile({ fileId, data, token: user?.token }),
    {
      onSuccess: (data, { fileId }) => {
        queryClient.invalidateQueries(["clientFile", fileId]);
        queryClient.invalidateQueries("filesByProject");
      },
    }
  );
};

// Delete a client file
export const useDeleteClientFile = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  return useMutation(
    ({ fileId }) => deleteClientFile({ fileId, token: user?.token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("filesByProject");
      },
    }
  );
};

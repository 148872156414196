import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ProjectStatusCard = ({ data }) => {
  const chartData = {
    labels: ["Completed", "In Progress", "Pending"],
    datasets: [
      {
        label: "Projects",
        data: [data.completed, data.inProgress, data.pending],
        backgroundColor: [
          "rgba(75, 192, 192, 0.5)", // Completed
          "rgba(255, 206, 86, 0.5)", // In Progress
          "rgba(255, 99, 132, 0.5)", // Pending
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Remove x-axis grid lines
        },
      },
      y: {
        grid: {
          display: true, // Keep y-axis grid lines
        },
      },
    },
  };

  return (
    <div className="rounded-lg overflow-hidden shadow-sm bg-white p-6 col-span-2">
      <h3 className="text-lg font-semibold text-gray-700 mb-4">
        Project Status
      </h3>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default ProjectStatusCard;

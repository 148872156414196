import React, { useContext, useState, useEffect, useRef } from "react";
import { AuthContext } from "../../context/AuthProvider";
import { NavLink } from "react-router-dom";
import { getInitials } from "../../utils/helpers";

const Header = ({ sidebarOpen, setSidebarOpen }) => {
  const { user, logout } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleSidebar = (event) => {
    event.stopPropagation();
    setSidebarOpen((prev) => !prev);
  };

  return (
    <div className="bg-white fixed inset-x-0 top-0 z-50 flex h-14 items-center justify-between gap-12 px-4 transition sm:px-6 lg:left-72 lg:z-30 lg:px-8 backdrop-blur-sm lg:left-72">
      <div className="absolute inset-x-0 top-full h-px transition bg-zinc-900/10"></div>
      <div className="hidden lg:block lg:max-w-md lg:flex-auto" />
      <div className="flex items-center gap-5 lg:hidden">
        <button
          id="menu-button"
          type="button"
          className="flex h-6 w-6 items-center justify-center rounded-md transition hover:bg-zinc-900/5"
          onClick={toggleSidebar}
        >
          {sidebarOpen ? (
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 20 20"
              aria-hidden="true"
              strokeLinecap="round"
              className="w-2.5 stroke-zinc-900"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          ) : (
            <svg
              viewBox="0 0 10 9"
              fill="none"
              strokeLinecap="round"
              aria-hidden="true"
              className="w-2.5 stroke-zinc-900"
            >
              <path d="M.5 1h9M.5 8h9M.5 4.5h9"></path>
            </svg>
          )}
        </button>
        <span className="self-center text-xl font-semibold whitespace-nowrap">
          {"Plura.design"}
        </span>
      </div>
      <div className="flex items-center gap-5">
        <div className="ml-4 flex items-center md:ml-6">
          <div className="relative ml-3" ref={containerRef}>
            <div>
              <button
                type="button"
                className="relative flex max-w-xs items-center rounded-full bg-blue-600 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-100"
                id="user-menu-button"
                aria-expanded={open}
                aria-haspopup="true"
                onClick={() => setOpen(!open)}
              >
                <span className="absolute -inset-1.5"></span>
                <span className="sr-only">Open user menu</span>
                <span className="h-8 w-8 rounded-full bg-blue-600 text-white flex items-center justify-center">
                  {user && getInitials(user.username)}
                </span>
              </button>
            </div>
            {open && (
              <div
                className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu-button"
                tabIndex="-1"
              >
                <span className="block w-full text-left px-4 py-2 text-sm text-gray-700">
                  <b>{user?.company_name}</b> <br />
                  {user?.username}
                </span>
                <hr />
                <NavLink
                  to="/settings?tab=Users"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                >
                  Profile
                </NavLink>
                <NavLink
                  to="/settings"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                >
                  Settings
                </NavLink>
                <button
                  onClick={logout}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                >
                  Sign Out
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

import CryptoJS from "crypto-js";

const SECRET_KEY = "your-secret-key";

export const generateEncryptedLink = (companyId, projectId) => {
  const data = { company_id: companyId, project_id: projectId };
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    SECRET_KEY
  ).toString();
  return `/shared/${encodeURIComponent(encrypted)}`;
};

export const decryptToken = (encryptedToken) => {
  try {
    const bytes = CryptoJS.AES.decrypt(
      decodeURIComponent(encryptedToken),
      SECRET_KEY
    );
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (error) {
    console.error("Invalid or corrupted token:", error);
    return null;
  }
};

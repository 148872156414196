import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { getInitials } from "../../../../utils/helpers";
import TaskModal from "./TaskModal";
import {
  useCreateTask,
  useUpdateTask,
} from "../../../../hooks/useTasks/useTasks";
import TaskCreationModal from "./TaskCreationModal";

const TaskBoard = ({ projectId, data = [] }) => {
  const [tasks, setTasks] = useState(data);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isAddingTask, setIsAddingTask] = useState(false);
  const [newTaskGroup, setNewTaskGroup] = useState("");

  useEffect(() => {
    setTasks(data);
  }, [data]);

  const createTaskMutation = useCreateTask();
  const updateTaskMutation = useUpdateTask();

  const handleOnDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    // Get the dragged task
    const draggedTask = tasks.find((task) => task.id === result.draggableId);

    if (!draggedTask) return;

    // Check if the task was moved to a different status group
    const sourceStatus = source.droppableId;
    const destinationStatus = destination.droppableId;

    if (sourceStatus === destinationStatus) {
      // Reorder within the same group
      const reorderedTasks = tasks.filter(
        (task) => task.status === sourceStatus
      );
      const [removed] = reorderedTasks.splice(source.index, 1);
      reorderedTasks.splice(destination.index, 0, removed);

      // Update tasks state
      setTasks((prevTasks) => {
        const otherTasks = prevTasks.filter(
          (task) => task.status !== sourceStatus
        );
        return [...otherTasks, ...reorderedTasks];
      });
    } else {
      // Update the status of the dragged task
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === draggedTask.id
            ? { ...task, status: destinationStatus }
            : task
        )
      );

      // Call API to update the task status
      updateTaskMutation.mutate({
        taskId: draggedTask.id,
        data: { status: destinationStatus },
      });
    }
  };

  const handleAddTask = (group) => {
    setIsAddingTask(true);
    setNewTaskGroup(group);
  };

  const handleTaskSubmit = (taskData) => {
    createTaskMutation.mutate(
      { ...taskData, status: newTaskGroup },
      {
        onSuccess: (newTask) => {
          setTasks((prevTasks) => ({
            ...prevTasks,
            [newTaskGroup]: [...prevTasks[newTaskGroup], newTask],
          }));
          setIsAddingTask(false);
        },
        onError: (error) => {
          console.error("Error adding task:", error);
        },
      }
    );
  };

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <TaskGroup
            title="To Do's"
            tasks={tasks?.filter((task) => task?.status === "todos") || []}
            droppableId="todos"
            setSelectedTask={setSelectedTask}
            onAddTask={() => handleAddTask("todos")}
          />
          <TaskGroup
            title="In Progress"
            tasks={tasks?.filter((task) => task?.status === "inProgress") || []}
            droppableId="inProgress"
            setSelectedTask={setSelectedTask}
            onAddTask={() => handleAddTask("inProgress")}
          />
          <TaskGroup
            title="In Review"
            tasks={tasks?.filter((task) => task?.status === "inReview") || []}
            droppableId="inReview"
            setSelectedTask={setSelectedTask}
            onAddTask={() => handleAddTask("inReview")}
          />
          <TaskGroup
            title="Completed"
            tasks={tasks?.filter((task) => task?.status === "completed") || []}
            droppableId="completed"
            setSelectedTask={setSelectedTask}
            onAddTask={() => handleAddTask("completed")}
          />
        </div>
      </DragDropContext>
      {!!selectedTask && (
        <TaskModal task={selectedTask} onClose={() => setSelectedTask(null)} />
      )}
      {isAddingTask && (
        <TaskCreationModal
          projectId={projectId}
          onClose={() => setIsAddingTask(false)}
          onSubmit={handleTaskSubmit}
        />
      )}
    </>
  );
};

const TaskGroup = ({
  title,
  tasks,
  droppableId,
  setSelectedTask,
  onAddTask,
}) => {
  return (
    <Droppable droppableId={droppableId}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className="bg-gray-100 p-4 rounded-lg shadow min-h-[200px] flex flex-col"
        >
          {/* Title with Badge */}
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <h2 className="text-gray-500 font-bold mr-2">{title}</h2>
              <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded-md">
                {tasks?.length || 0}
              </span>
            </div>
          </div>

          {/* Task List */}
          <div className="flex-grow">
            {tasks?.map((task, index) => (
              <Draggable
                key={task.id.toString()}
                draggableId={task.id.toString()}
                index={index}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="bg-white p-3 mb-2 rounded-md border border-gray-200 cursor-pointer"
                    onClick={() => setSelectedTask(task)}
                  >
                    <Task task={task} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>

          <button
            onClick={onAddTask}
            className="mt-2 bg-gray-100 hover:bg-gray-200 text-gray-400 hover:text-gray-500 font-medium py-1 px-3 rounded-md w-full"
          >
            {`+ Add Task`}
          </button>
        </div>
      )}
    </Droppable>
  );
};

const Task = ({ task }) => {
  return (
    <div role="button">
      <div>
        <div className="flex items-center text-sm font-semibold text-gray-900">
          <span className="mb-1">{task.title}</span>
        </div>
        {task?.phase && (
          <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded whitespace-nowrap">
            {task?.phase}
          </span>
        )}
        {/* User Avatars */}
        {task?.users && (
          <div className="flex -space-x-3 my-2">
            {task.users.map((user) => (
              <div key={user.id}>
                <span className="sr-only">Open user menu</span>
                <span
                  className="h-6 w-6 rounded-full bg-blue-600 text-white flex items-center justify-center border border-white"
                  style={{ fontSize: "10px" }}
                >
                  {getInitials(user.username)}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
      <hr className="my-3" />

      <div className="flex items-center space-x-4">
        {/* Subtasks Progress */}
        {task?.subtasks?.length > 0 && (
          <span className="flex items-center text-gray-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            <span className="ml-1 text-xs">
              {`${
                task?.subtasks?.filter((subtask) => !!subtask?.checked).length
              } of ${task?.subtasks?.length}`}
            </span>
          </span>
        )}

        {/* Comments Count */}
        {task?.comments !== undefined && (
          <span className="flex items-center text-gray-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 0 1-.923 1.785A5.969 5.969 0 0 0 6 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337Z"
              />
            </svg>
            <span className="ml-1 text-xs">{task.comments}</span>
          </span>
        )}

        {/* Due Date */}
        {task?.dueDate && (
          <div className="text-xs text-gray-500">{task.dueDate}</div>
        )}
      </div>
    </div>
  );
};

export default TaskBoard;

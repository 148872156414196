import { useQuery, useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import { AuthContext } from "../context/AuthProvider";
import axiosInstance from "./axiosInstance";

const API_URL = `${process.env.REACT_APP_API_URL}`;

const fetchCompanyInfo = async (companyId, token) => {
  const response = await axiosInstance.get(
    `${API_URL}/companies/${companyId}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

export const useCompanyInfo = (companyId) => {
  const { user } = useContext(AuthContext);
  return useQuery(["companyInfo", companyId], () =>
    fetchCompanyInfo(companyId, user?.token)
  );
};

const updateCompanyInfo = async ({ companyId, token, ...data }) => {
  const response = await axiosInstance.put(
    `${API_URL}/companies/${companyId}`,
    data,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

export const useUpdateCompanyInfo = (companyId) => {
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();
  return useMutation(
    (data) => updateCompanyInfo({ companyId, token: user?.token, ...data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["companyInfo", companyId]);
      },
    }
  );
};

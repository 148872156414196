import React from "react";
import { Link } from "react-router-dom";
import TableContainer from "../../../../components/common/tables/TableContainer";
import { INVOICES_MIN_TABLE_COLUMNS } from "../../../../utils/tableColumns";

const ClientInvoices = ({ data }) => {
  return (
    <div>
      {data && data.length > 0 ? (
        <div>
          <h3 className="text-lg font-semibold text-gray-700 mb-4">
            Invoices for this Client
          </h3>
          <TableContainer
            hasCheckbox={false}
            columns={INVOICES_MIN_TABLE_COLUMNS}
            columnType={"invoices"}
            rowType={"invoices-min"}
            data={data}
          />
        </div>
      ) : (
        <div className="p-6 border border-dashed border-gray-300 rounded-lg bg-gray-50 text-center">
          <h3 className="text-lg font-semibold text-gray-700">
            No invoices for this client
          </h3>
          <p className="text-gray-500 mt-2 mb-4">
            Generate your first invoice to track your services and help this
            client see the value you're bringing.
          </p>
          <Link
            to={"/invoices/create"}
            className="bg-green-500 text-white px-6 py-2 rounded shadow hover:bg-green-600"
          >
            Create Invoice
          </Link>
        </div>
      )}
    </div>
  );
};

export default ClientInvoices;

import React from "react";

const TodaysTasksCard = ({ tasks }) => {
  return (
    <div className="rounded-lg overflow-hidden shadow-sm bg-white p-6 col-span-2">
      <h3 className="text-lg font-semibold text-gray-700 mb-4">
        Today's Tasks
      </h3>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th className="px-6 py-3">Task</th>
              <th className="px-6 py-3">Project</th>
              <th className="px-6 py-3">Due Date</th>
            </tr>
          </thead>
          <tbody>
            {tasks.map((task, index) => (
              <tr key={index}>
                <td className="px-6 py-3">{task.title}</td>
                <td className="px-6 py-3">{task.project_name}</td>
                <td className="px-6 py-3">{task.dueDate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default TodaysTasksCard;

import React from "react";

const AlertProjectsCard = ({ projects }) => {
  return (
    <div className="rounded-lg overflow-hidden shadow-sm bg-white p-6 col-span-2">
      <h3 className="text-lg font-semibold text-gray-700 mb-4">
        Projects Not Updated Recently
      </h3>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th className="px-6 py-3">Project</th>
              <th className="px-6 py-3">Last Update</th>
              <th className="px-6 py-3">Status</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project, index) => (
              <tr key={index}>
                <td className="px-6 py-3">{project.name}</td>
                <td className="px-6 py-3">{project.lastUpdated}</td>
                <td className="px-6 py-3">{project.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AlertProjectsCard;

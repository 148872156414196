import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TaskStatusCard = ({ data }) => {
  const chartData = {
    labels: ["To Do", "In Progress", "In Review", "Done"],
    datasets: [
      {
        label: "Tasks",
        data: [data.todos, data.inProgress, data.inReview, data.done],
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(75, 192, 192, 0.5)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Remove x-axis grid lines
        },
      },
      y: {
        grid: {
          display: true, // Keep y-axis grid lines
        },
      },
    },
  };

  return (
    <div className="rounded-lg overflow-hidden shadow-sm bg-white p-6 col-span-2">
      <h3 className="text-lg font-semibold text-gray-700 mb-4">Task Status</h3>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default TaskStatusCard;

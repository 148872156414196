import React from "react";

const ErrorMessage = () => {
  return (
    <div className={`bg-white rounded-md p-6 border-gray-100 text-center`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-12 h-12 text-red-500 mx-auto mb-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
        />
      </svg>

      <h2 className="text-2xl font-semibold text-gray-800 mb-2">
        {"Unable to Load Data"}
      </h2>
      <p className="text-gray-600">
        {"Something went wrong, please try again later."}
      </p>
    </div>
  );
};

export default ErrorMessage;

import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/AuthProvider";
import {
  useCompanyInfo,
  useUpdateCompanyInfo,
} from "../../../../hooks/useCompany";
import CardHeadings from "../../../../components/common/pageHeadings/CardHeadings";
import ErrorMessage from "../../../../components/common/empty/ErrorMessage";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";

const Account = () => {
  const { user } = useContext(AuthContext);
  const companyId = user?.company_id;

  const { data: companyInfo, isLoading, isError } = useCompanyInfo(companyId);
  const updateCompanyInfo = useUpdateCompanyInfo(companyId);

  const [formData, setFormData] = useState({
    name: "",
    tier: "",
    country: "",
    city: "",
    about: "",
    website: "",
    facebook: "",
    instagram: "",
    tiktok: "",
    phone: "",
    email: "",
    address: "",
    terms_and_conditions: "",
  });
  const [alert, setAlert] = useState(null);
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    if (companyInfo) {
      setFormData({
        name: companyInfo.name,
        tier: companyInfo.tier,
        country: companyInfo.country || "",
        city: companyInfo.city || "",
        about: companyInfo.about || "",
        website: companyInfo.website || "",
        facebook: companyInfo.facebook || "",
        instagram: companyInfo.instagram || "",
        tiktok: companyInfo.tiktok || "",
        phone: companyInfo.phone || "",
        email: companyInfo.email || "",
        address: companyInfo.address || "",
        terms_and_conditions: companyInfo.terms_and_conditions || "",
      });
    }
  }, [companyInfo]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(value) && !!value) {
        setEmailError("Please enter a valid email address.");
      } else {
        setEmailError("");
      }
    }

    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSave = () => {
    if (emailError) {
      setAlert({
        type: "error",
        message: "Please fix the errors in the form.",
      });
    } else
      updateCompanyInfo.mutate(formData, {
        onSuccess: () => {
          setAlert({
            type: "success",
            message: "Company info updated successfully!",
          });
        },
        onError: () => {
          setAlert({
            type: "error",
            message: "Failed to update company info.",
          });
        },
      });
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <ErrorMessage />;

  return (
    <div className="bg-white rounded-md p-4 border border-gray-200 w-full">
      {alert && (
        <div
          className={`p-3 mb-4 text-sm rounded-lg ${
            alert.type === "success"
              ? "text-green-800 bg-green-50"
              : "text-red-800 bg-red-50"
          }`}
          role="alert"
        >
          {alert.message}
          <button
            onClick={() => setAlert(null)}
            className="float-right text-sm text-gray-700"
          >
            &times;
          </button>
        </div>
      )}
      <CardHeadings
        title="Company Information"
        description="Details and settings for your company."
      />

      <form className="max-w-md space-y-5 mt-4">
        {/* General Information Section */}
        <div>
          <label className="max-w-md block text-sm font-medium text-gray-700 mb-1">
            Company Name
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your company name"
            className="max-w-md p-2 border rounded w-full focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div>
          <label className="max-w-md block text-sm font-medium text-gray-700 mb-1">
            Tier
          </label>
          <input
            type="text"
            value={formData.tier === "0" ? "Free" : "Paid"}
            className="max-w-md p-2 border rounded w-full bg-gray-100 text-gray-500"
            readOnly
            disabled
          />
        </div>
        <div>
          <label className="max-w-md block text-sm font-medium text-gray-700 mb-1">
            About
          </label>
          <textarea
            name="about"
            value={formData.about}
            onChange={handleChange}
            placeholder="Describe your company"
            className="max-w-md p-2 border rounded w-full h-24 focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <hr />

        {/* Location Section */}
        <h3 className="max-w-md text-md font-semibold text-gray-900 mb-2">
          Location
        </h3>
        <div>
          <label className="max-w-md block text-sm font-medium text-gray-700 mb-1">
            Country
          </label>
          <CountryDropdown
            value={formData.country}
            onChange={(val) =>
              setFormData((prev) => ({ ...prev, country: val }))
            }
            placeholder="Select a country"
            className="max-w-md p-2 border rounded w-full focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div>
          <label className="max-w-md block text-sm font-medium text-gray-700 mb-1">
            City
          </label>
          <RegionDropdown
            country={formData.country}
            value={formData.city}
            onChange={(val) => setFormData((prev) => ({ ...prev, city: val }))}
            placeholder="Select a city"
            className="max-w-md p-2 border rounded w-full focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div>
          <label className="max-w-md block text-sm font-medium text-gray-700 mb-1">
            Address
          </label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            placeholder="Enter your address"
            className="max-w-md p-2 border rounded w-full focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <hr />

        {/* Social Links Section */}
        <h3 className="max-w-md text-md font-semibold text-gray-900 mb-2">
          Social Links
        </h3>
        {["website", "facebook", "instagram", "tiktok"].map((field) => (
          <div key={field}>
            <label className="max-w-md block text-sm font-medium text-gray-700 mb-1 capitalize">
              {field}
            </label>
            <input
              type="text"
              name={field}
              value={formData[field]}
              onChange={handleChange}
              placeholder={`Enter your ${field} URL`}
              className="max-w-md p-2 border rounded w-full focus:ring-2 focus:ring-blue-500"
            />
          </div>
        ))}
        <hr />
        {/* Contact Details Section */}
        <h3 className="max-w-md text-md font-semibold text-gray-900 mb-2">
          Contact Details
        </h3>
        <div>
          <label className="max-w-md block text-sm font-medium text-gray-700 mb-1">
            Phone
          </label>
          <PhoneInput
            value={formData.phone}
            onChange={(val) => setFormData((prev) => ({ ...prev, phone: val }))}
            className="max-w-md w-full focus:ring-2 focus:ring-blue-500"
            inputClass="p-2 border rounded w-full"
          />
        </div>
        <div>
          <label className="max-w-md block text-sm font-medium text-gray-700 mb-1">
            Email
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email address"
            className="max-w-md p-2 border rounded w-full focus:ring-2 focus:ring-blue-500"
          />
          {emailError && (
            <p className="text-red-600 text-sm mt-1">{emailError}</p>
          )}
        </div>
        <hr />
        {/* Legal Section */}
        <h3 className="max-w-md text-md font-semibold text-gray-900 mb-2">
          Legal
        </h3>
        <div>
          <label className="max-w-md block text-sm font-medium text-gray-700 mb-1">
            Terms and Conditions
          </label>
          <textarea
            name="terms_and_conditions"
            value={formData.terms_and_conditions}
            onChange={handleChange}
            placeholder="Enter terms and conditions"
            className="max-w-md p-2 border rounded w-full h-24 focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Save Button */}
        <div className="max-w-md flex mt-6">
          <button
            onClick={handleSave}
            className="max-w-md px-5 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            disabled={updateCompanyInfo.isLoading}
          >
            {updateCompanyInfo.isLoading ? "Saving..." : "Save Changes"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Account;

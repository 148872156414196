import React, { useState } from "react";
import TableContainer from "../../../../components/common/tables/TableContainer";
import Pagination from "../../../../components/common/tables/Pagination";
import {
  INVOICES_TABLE_COLUMNS,
  PURCHASE_ORDERS_TABLE_COLUMNS,
} from "../../../../utils/tableColumns";
import CardHeadings from "../../../../components/common/pageHeadings/CardHeadings";
import { useExpensesByProject } from "../../../../hooks/useExpenses/useExpenses";
import { INVOICES_MOCK_DATA } from "../../../../utils/mockData";
import { useNavigate, useLocation } from "react-router-dom";
import SlideOver from "../../../../components/layout/SlideOver";
import PurchaseOrder from "../../purchaseOrders/PurchaseOrder";
import InvoiceDetails from "../../invoices/InvoiceDetails";
import { useInvoicesByProject } from "../../../../hooks/useInvoices/useInvoices";

const ProjectFinance = ({ projectId }) => {
  const [currentPageInvoice, setCurrentPageInvoice] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [sideOveOpenInvoice, setSideOverOpenInvoice] = useState(false);
  const [selectedRowInvoice, setSelectedRowInvoice] = useState({});
  const [sideOveOpenExpense, setSideOverOpenExpense] = useState(false);
  const [selectedRowExpense, setSelectedRowExpense] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: dataInvoices,
    isLoading: isLoadingInvoices,
    isError: isErrorInvoices,
    refetch,
  } = useInvoicesByProject(projectId, currentPageInvoice, 10);

  const { data, isLoading, isError } = useExpensesByProject(
    projectId,
    currentPage,
    10
  );

  const invoices = dataInvoices?.invoices || [];
  const totalItemsInvoice = dataInvoices?.pagination?.total_items || 0;

  const expenses = data?.expenses || [];
  const totalItems = data?.pagination?.total_items || 0;

  const handlePageChangeInvoice = (page) => {
    setCurrentPageInvoice(page);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowClickInvoice = (data) => {
    setSideOverOpenInvoice((prevState) => !prevState);
    setSelectedRowInvoice(data || {});
  };

  const handleRowClickExpense = (data) => {
    setSideOverOpenExpense((prevState) => !prevState);
    setSelectedRowExpense(data || {});
  };

  // Function to navigate to the expense creation page with project context and finance view
  const handleAddExpense = () => {
    navigate(
      `/purchase-orders/create?projectId=${projectId}&redirect=${location.pathname}`
    );
  };

  const handleAddInvoice = () => {
    navigate(
      `/invoices/create?projectId=${projectId}&redirect=${location.pathname}`
    );
  };

  return (
    <div className="bg-white rounded-lg">
      <CardHeadings
        title={"Invoices"}
        showCta={true}
        ctaLabel={"Add Invoice"}
        handleCtaAction={handleAddInvoice}
      />
      <TableContainer
        hasCheckbox={true}
        columns={INVOICES_TABLE_COLUMNS}
        columnType={"invoices"}
        rowType={"invoices"}
        data={invoices}
        loading={isLoadingInvoices}
        error={isErrorInvoices}
        handleRowClick={handleRowClickInvoice}
      />
      <SlideOver
        open={sideOveOpenInvoice}
        setOpen={setSideOverOpenInvoice}
        title="Invoice Details"
      >
        <InvoiceDetails
          data={selectedRowInvoice}
          closeSidebar={() => setSideOverOpenInvoice(false)}
        />
      </SlideOver>
      <Pagination
        currentPage={currentPageInvoice}
        totalCount={totalItemsInvoice}
        pageSize={10}
        onPageChange={handlePageChangeInvoice}
      />
      <hr className="mt-10 mb-4" />
      <CardHeadings
        title={"Expenses"}
        showCta={true}
        ctaLabel={"Add Expense"}
        handleCtaAction={handleAddExpense}
      />
      <TableContainer
        hasCheckbox={true}
        columns={PURCHASE_ORDERS_TABLE_COLUMNS}
        columnType={"expenses"}
        rowType={"purchase_orders"}
        data={expenses}
        loading={isLoading}
        error={isError}
        handleRowClick={handleRowClickExpense}
      />
      <Pagination
        currentPage={currentPage}
        totalCount={totalItems}
        pageSize={10}
        onPageChange={handlePageChange}
      />
      <SlideOver
        open={sideOveOpenExpense}
        setOpen={setSideOverOpenExpense}
        title="Expense Details"
      >
        <PurchaseOrder
          data={selectedRowExpense}
          closeSidebar={() => setSideOverOpenExpense(false)}
        />
      </SlideOver>
    </div>
  );
};

export default ProjectFinance;

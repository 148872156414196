import React, { useEffect, useRef } from "react";

const CreateUser = ({
  showModal,
  setShowModal,
  handleCreateUser,
  newUser,
  setNewUser,
  createUserMutation,
  alert,
}) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowModal]);

  return (
    <div>
      {showModal && (
        <>
          <div
            id="modal-overlay"
            className="fixed inset-0 bg-black opacity-50 z-40"
          ></div>
          <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">
              <div
                ref={modalRef} // Apply ref to modal content container
                className="bg-white rounded-lg shadow-xl p-6 min-w-80 relative"
              >
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-semibold">Create New User</h2>
                  <button
                    onClick={() => setShowModal(false)}
                    className="text-gray-500 hover:text-gray-700 font-bold text-xl"
                    aria-label="Close modal"
                  >
                    &times;
                  </button>
                </div>

                {alert && alert.type === "error" && (
                  <div
                    className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50"
                    role="alert"
                  >
                    <svg
                      className="flex-shrink-0 inline w-4 h-4 mr-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <span className="sr-only">Info</span>
                    <div>{alert.message}</div>
                  </div>
                )}

                <form onSubmit={handleCreateUser}>
                  <label className="block mb-2">
                    Name:
                    <input
                      required
                      type="text"
                      value={newUser.username}
                      onChange={(e) =>
                        setNewUser({ ...newUser, username: e.target.value })
                      }
                      className="border p-1 rounded w-full"
                    />
                  </label>
                  <label className="block mb-2">
                    Email:
                    <input
                      required
                      type="email"
                      value={newUser.email}
                      onChange={(e) =>
                        setNewUser({ ...newUser, email: e.target.value })
                      }
                      className="border p-1 rounded w-full"
                    />
                  </label>
                  <label className="block mb-2">
                    Role:
                    <select
                      required
                      value={newUser.role}
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          role: parseInt(e.target.value, 10),
                        })
                      }
                      className="border p-1 rounded w-full"
                    >
                      <option value="0">Admin</option>
                      <option value="1">Manager</option>
                      <option value="2">Designer</option>
                      <option value="3">View only</option>
                    </select>
                  </label>
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="px-4 py-2 bg-gray-200 text-gray-700 rounded mr-2"
                      onClick={() => setShowModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="px-4 py-2 bg-blue-600 text-white rounded"
                      disabled={createUserMutation.isLoading}
                    >
                      {createUserMutation.isLoading ? "Creating..." : "Create"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CreateUser;

import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCreateClientFile } from "../../../hooks/useFiles/useFiles";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import DefaultCard from "../../../components/common/cards/DefaultCard";

const CreateFile = () => {
  const { id: projectId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    file_type: "Canva",
    file_url: "",
    is_public: false,
    project_id: projectId || "",
  });
  const [error, setError] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const { mutate: createFile, isLoading } = useCreateClientFile();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateUrl = (url) => {
    try {
      const parsedUrl = new URL(url);
      return !!parsedUrl;
    } catch (error) {
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError({});

    // Validations
    if (!formData.name.trim()) {
      setError({ name: "File name is required." });
      return;
    }
    if (!formData.file_url || !validateUrl(formData.file_url)) {
      setError({ file_url: "A valid URL is required." });
      return;
    }
    if (!formData.project_id) {
      setError({ project_id: "Project ID is required." });
      return;
    }

    createFile(formData, {
      onSuccess: () => {
        setSuccessMessage("File created successfully!");
        setTimeout(() => {
          navigate(`/projects/${formData.project_id}`);
        }, 2000);
      },
      onError: (err) => {
        setError({
          submit:
            err.response?.data?.description ||
            "An error occurred while creating the file.",
        });
      },
    });
  };

  return (
    <div>
      <PageHeadings title="Create Client File" tags={[]} showCta={false} />
      <DefaultCard>
        <div className="p-4 bg-white rounded-md">
          {successMessage && (
            <div className="mb-4 p-4 text-green-700 bg-green-100 rounded">
              {successMessage}
            </div>
          )}

          {error.submit && (
            <div className="mb-4 p-4 text-red-700 bg-red-100 rounded">
              {error.submit}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            {/* File Name */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                File Name
              </label>
              <input
                type="text"
                name="name"
                placeholder="Enter a descriptive file name"
                value={formData.name}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
                required
              />
              {error.name && (
                <div className="text-red-500 text-sm mt-1">{error.name}</div>
              )}
            </div>

            {/* File Type */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                File Type
              </label>
              <select
                name="file_type"
                value={formData.file_type}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              >
                <option value="canva">Canva</option>
                <option value="google_slides">Google Slides</option>
                <option value="google_doc">Google Doc</option>
                <option value="google_sheet">Google Sheet</option>
                <option value="link">Link</option>
                {/* Add more types if needed */}
              </select>
            </div>

            {/* File URL */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                File URL
              </label>
              <input
                type="url"
                name="file_url"
                placeholder="https://example.com/file.pdf"
                value={formData.file_url}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
                required
              />
              {error.file_url && (
                <div className="text-red-500 text-sm mt-1">
                  {error.file_url}
                </div>
              )}
            </div>

            {/* Public Toggle */}
            <div className="flex items-center mt-4">
              <input
                type="checkbox"
                name="is_public"
                checked={formData.is_public}
                onChange={handleChange}
                className="mr-2"
              />
              <label className="text-sm text-gray-700">Make Public</label>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded"
              disabled={isLoading}
            >
              {isLoading ? "Creating..." : "Create File"}
            </button>
          </form>
        </div>
      </DefaultCard>
    </div>
  );
};

export default CreateFile;

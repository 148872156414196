import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import CreatableSelect from "react-select/creatable";
import "react-phone-input-2/lib/style.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import {
  useDeleteProvider,
  useUpdateProvider,
} from "../../../hooks/useProviders/useProviders";

const ProviderDetails = ({ data, closeSidebar }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [providerData, setProviderData] = useState(data);
  const [initialProviderData, setInitialProviderData] = useState(data);
  const [error, setError] = useState({});
  const [defaultCountry, setDefaultCountry] = useState("us");
  const [newServiceOptions, setNewServiceOptions] = useState([]);
  const navigate = useNavigate();

  const deleteProvider = useDeleteProvider();
  const updateProvider = useUpdateProvider();

  useEffect(() => {
    setProviderData(data);
    setInitialProviderData(data);

    const getDefaultCountry = () => {
      const locale = navigator.language || navigator.userLanguage;
      const country = locale.split("-")[1]?.toLowerCase();
      if (country) setDefaultCountry(country);
    };
    getDefaultCountry();

    const serviceOptions = Object.keys(data.services || {}).map((service) => ({
      label: service,
      value: service,
    }));
    setNewServiceOptions(serviceOptions);
  }, [data]);

  const handleEditToggle = () => {
    setShowOptions(false);
    setIsEditing(!isEditing);
  };

  const handleDelete = async () => {
    setShowOptions(false);
    if (window.confirm("Are you sure you want to delete this partner?")) {
      try {
        await deleteProvider.mutateAsync({ providerId: providerData.id });
        closeSidebar();
        navigate("/partners");
      } catch (err) {
        setError({ submit: "Failed to delete provider. Please try again." });
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProviderData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePhoneChange = (value) => {
    setProviderData((prevData) => ({ ...prevData, phone: value }));
  };

  const handleServiceChange = (selectedServices) => {
    const updatedServices = {};
    selectedServices.forEach((service) => {
      updatedServices[service.value] = true;
    });
    setProviderData((prevData) => ({ ...prevData, services: updatedServices }));
    setNewServiceOptions(selectedServices);
  };

  const validateInputs = () => {
    const errors = {};
    if (!providerData.contact_name.trim())
      errors.contact_name = "Contact name is required.";
    if (!providerData.company_name.trim())
      errors.company_name = "Company name is required.";
    if (providerData.email && !/^\S+@\S+\.\S+$/.test(providerData.email))
      errors.email = "Enter a valid email.";
    if (providerData.website && !/^https?:\/\/\S+$/.test(providerData.website))
      errors.website = "Enter a valid URL.";
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = async () => {
    if (!validateInputs()) return;
    try {
      await updateProvider.mutateAsync({
        providerId: providerData.id,
        data: providerData,
      });
      setInitialProviderData(providerData);
      setIsEditing(false);
    } catch (err) {
      setError({ submit: "Failed to update provider. Please try again." });
    }
  };

  const handleCancelEdit = () => {
    setProviderData(initialProviderData);
    setIsEditing(false);
    setError({});
  };

  return (
    <div className="space-y-6 p-6">
      {error.submit && (
        <div
          className="p-4 mb-4 text-sm text-red-800 bg-red-50 rounded-lg"
          role="alert"
        >
          {error.submit}
        </div>
      )}
      {isEditing ? (
        <div className="space-y-4">
          <div>
            <input
              type="text"
              name="contact_name"
              value={providerData.contact_name}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
              required
              placeholder="Contact Name"
            />
            {error.contact_name && (
              <div className="text-red-500 text-sm mt-1">
                {error.contact_name}
              </div>
            )}
          </div>

          <div>
            <input
              type="text"
              name="company_name"
              value={providerData.company_name}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
              required
              placeholder="Company Name"
            />
            {error.company_name && (
              <div className="text-red-500 text-sm mt-1">
                {error.company_name}
              </div>
            )}
          </div>

          <div>
            <PhoneInput
              country={defaultCountry}
              value={providerData.phone}
              onChange={handlePhoneChange}
              placeholder="Provider Phone"
              inputClass="w-full border p-2 rounded"
              inputStyle={{ width: "inherit" }}
              enableSearch={true}
            />
          </div>

          <div>
            <input
              type="email"
              name="email"
              value={providerData.email}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
              placeholder="Provider Email"
            />
            {error.email && (
              <div className="text-red-500 text-sm mt-1">{error.email}</div>
            )}
          </div>

          <div>
            <input
              type="url"
              name="website"
              value={providerData.website}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
              placeholder="Website URL"
            />
            {error.website && (
              <div className="text-red-500 text-sm mt-1">{error.website}</div>
            )}
          </div>

          <div>
            <CountryDropdown
              value={providerData.country}
              onChange={(val) =>
                setProviderData((prevData) => ({ ...prevData, country: val }))
              }
              className="border p-2 w-full rounded"
            />
          </div>

          <div>
            <RegionDropdown
              country={providerData.country}
              value={providerData.city}
              onChange={(val) =>
                setProviderData((prevData) => ({ ...prevData, city: val }))
              }
              className="border p-2 w-full rounded"
            />
          </div>

          {/* Services as react-select Creatable multi-select */}
          <div>
            <CreatableSelect
              isMulti
              value={newServiceOptions}
              onChange={handleServiceChange}
              options={newServiceOptions}
              placeholder="Select or add services"
              className="mt-2"
              styles={{
                control: (base) => ({
                  ...base,
                  borderColor: "#d1d5db",
                  boxShadow: "none",
                  "&:hover": { borderColor: "#d1d5db" },
                }),
              }}
              noOptionsMessage={() => "Type to add a new service"}
              formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
            />
          </div>

          {/* Save and Cancel buttons */}
          <div className="mt-4 flex space-x-4">
            <button
              onClick={handleSave}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Save
            </button>
            <button
              onClick={handleCancelEdit}
              className="bg-gray-500 text-white px-4 py-2 rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="space-y-2">
          {/* View details here if not editing */}
          <div className="flex items-center justify-between mb-4">
            <div>
              <h5 className="text-xl font-bold leading-none text-gray-900">
                {providerData.contact_name}
              </h5>
              <p className="text-sm text-gray-600">
                {providerData.company_name}
              </p>
            </div>

            <div className="relative">
              <button
                onClick={() => setShowOptions(!showOptions)}
                className="p-1 rounded hover:bg-gray-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                  />
                </svg>
              </button>

              {/* Dropdown Menu */}
              {showOptions && (
                <div className="absolute right-0 mt-2 w-32 bg-white border rounded shadow-lg">
                  <button
                    onClick={handleEditToggle}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Edit
                  </button>
                  <button
                    onClick={handleDelete}
                    className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
          </div>

          <div>
            <p>
              <span className="block text-gray-500">Phone:</span>{" "}
              {providerData.phone || "---"}
            </p>
          </div>

          <div>
            <p>
              <span className="block text-gray-500">Email:</span>{" "}
              {providerData.email || "---"}
            </p>
          </div>

          <div>
            <p>
              <span className="block text-gray-500">Website:</span>{" "}
              {providerData.website ? (
                <a
                  href={providerData.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  {providerData.website}
                </a>
              ) : (
                "---"
              )}
            </p>
          </div>

          <div>
            <p>
              <span className="block text-gray-500">Country:</span>{" "}
              {providerData.country || "---"}
            </p>
          </div>

          <div>
            <p>
              <span className="block text-gray-500">City:</span>{" "}
              {providerData.city || "---"}
            </p>
          </div>

          <div>
            <p className="text-gray-500">Services:</p>
            <div className="flex flex-wrap gap-2 mt-1">
              {Object.keys(providerData.services || {}).length > 0 ? (
                Object.keys(providerData.services).map((service, index) => (
                  <span
                    key={index}
                    className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded"
                  >
                    {service}
                  </span>
                ))
              ) : (
                <p className="text-gray-600">No services added</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProviderDetails;

import React, { useState } from "react";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import { INVOICES_TABLE_COLUMNS } from "../../../utils/tableColumns";
import DefaultCard from "../../../components/common/cards/DefaultCard";
import FiltersContainer from "../../../components/common/filters/FiltersContainer";
import TableContainer from "../../../components/common/tables/TableContainer";
import Pagination from "../../../components/common/tables/Pagination";
import { useNavigate } from "react-router-dom";
import { useCompanyInvoices } from "../../../hooks/useInvoices/useInvoices";
import SlideOver from "../../../components/layout/SlideOver";
import InvoiceDetails from "./InvoiceDetails";

const Invoices = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sideOveOpen, setSideOverOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [perPage] = useState(10);
  const navigate = useNavigate();

  const { data, isLoading, isError, refetch } = useCompanyInvoices(
    currentPage,
    perPage
  );

  const handleAddExpense = () => {
    navigate("/invoices/create");
  };

  const handlePageChange = (page) => setCurrentPage(page);

  const handleRowClick = (data) => {
    setSideOverOpen((prevState) => !prevState);
    setSelectedRow(data || {});
  };

  const invoices = data?.invoices || [];
  const totalItems = data?.pagination?.total_items || 0;

  return (
    <div>
      <PageHeadings
        title={`Invoices`}
        tags={[]}
        showCta={true}
        ctaLabel={"Add Invoice"}
        handleCtaAction={handleAddExpense}
      />
      <DefaultCard>
        <FiltersContainer />
        <TableContainer
          hasCheckbox={true}
          columns={INVOICES_TABLE_COLUMNS}
          columnType={"invoices"}
          rowType={"invoices"}
          data={invoices}
          loading={isLoading}
          error={isError}
          handleRowClick={handleRowClick}
        />
        <Pagination
          currentPage={currentPage}
          totalCount={totalItems}
          pageSize={perPage}
          onPageChange={handlePageChange}
        />
      </DefaultCard>
      <SlideOver
        open={sideOveOpen}
        setOpen={setSideOverOpen}
        title="Invoice Details"
      >
        <InvoiceDetails
          data={selectedRow}
          closeSidebar={() => setSideOverOpen(false)}
        />
      </SlideOver>
    </div>
  );
};

export default Invoices;

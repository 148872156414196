import React from "react";

const EmptyPage = ({
  title,
  description,
  showCta = false,
  ctaLabel,
  handleCtaAction,
}) => {
  return (
    <section className="bg-white border border-gray-200 rounded-lg">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-8 mx-auto mb-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
            />
          </svg>

          <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">
            {title}
          </p>
          <p className="mb-4 text-lg font-light text-gray-500">{description}</p>
          {showCta && (
            <button
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none"
              onClick={handleCtaAction}
            >
              {ctaLabel}
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default EmptyPage;

import React from "react";
import Tabs from "../../../components/common/tabs/Tabs";
import Account from "./account/Account";
import Users from "./users/Users";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import Invoices from "./billing/Invoices";
import Billing from "./billing/Billing";

const Settings = () => {
  const tabs = [
    {
      label: "Account",
      content: <Account />,
    },
    {
      label: "Users",
      content: <Users />,
    },
    {
      label: "Billing",
      content: <Billing />,
    },
    {
      label: "Invoices",
      content: <Invoices />,
    },
  ];
  return (
    <div>
      <PageHeadings title={`Settings`} tags={[]} showCta={false} />
      <Tabs tabs={tabs} />
    </div>
  );
};

export default Settings;

import React, { useEffect, useState } from "react";
import { getInitials } from "../../../../utils/helpers";
import TaskModal from "./TaskModal";
import DefaultCard from "../../../../components/common/cards/DefaultCard";
import { useCreateTask } from "../../../../hooks/useTasks/useTasks";
import TaskCreationModal from "./TaskCreationModal";

// TaskList Component
const TaskList = ({ projectId, data = [] }) => {
  const [tasks, setTasks] = useState(data);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddingTask, setIsAddingTask] = useState(false);
  const [newTaskGroup, setNewTaskGroup] = useState("");

  useEffect(() => {
    setTasks(data);
  }, [data]);

  const createTaskMutation = useCreateTask();

  // Function to open the modal with the selected task
  const openModal = (task) => {
    setSelectedTask(task);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedTask(null);
    setIsModalOpen(false);
  };

  const handleAddTask = (group) => {
    setIsAddingTask(true);
    setNewTaskGroup(group);
  };

  const handleTaskSubmit = (taskData) => {
    createTaskMutation.mutate(
      { ...taskData, status: newTaskGroup },
      {
        onSuccess: (newTask) => {
          setTasks((prevTasks) => ({
            ...prevTasks,
            [newTaskGroup]: [...prevTasks[newTaskGroup], newTask],
          }));
          setIsAddingTask(false);
        },
        onError: (error) => {
          console.error("Error adding task:", error);
        },
      }
    );
  };

  return (
    <DefaultCard>
      <div className="space-y-6 p-5 bg-white rounded-lg">
        <TaskGroup
          title="To Do's"
          tasks={tasks?.filter((task) => task?.status === "todos") || []}
          openModal={openModal}
          onAddTask={() => handleAddTask("todos")}
        />
        <TaskGroup
          title="In Progress"
          tasks={tasks?.filter((task) => task?.status === "inProgress") || []}
          openModal={openModal}
          onAddTask={() => handleAddTask("inProgress")}
        />
        <TaskGroup
          title="In Review"
          tasks={tasks?.filter((task) => task?.status === "inReview") || []}
          openModal={openModal}
          onAddTask={() => handleAddTask("inReview")}
        />
        <TaskGroup
          title="Completed"
          tasks={tasks?.filter((task) => task?.status === "completed") || []}
          openModal={openModal}
          onAddTask={() => handleAddTask("completed")}
        />
      </div>

      {isModalOpen && <TaskModal task={selectedTask} onClose={closeModal} />}
      {isAddingTask && (
        <TaskCreationModal
          projectId={projectId}
          onClose={() => setIsAddingTask(false)}
          onSubmit={handleTaskSubmit}
        />
      )}
    </DefaultCard>
  );
};

export default TaskList;

const TaskGroup = ({ title, tasks, openModal, onAddTask }) => {
  return (
    <div className="mb-8 border-b">
      <div className="flex items-center justify-between mb-4">
        {/* Title with Task Count Badge */}
        <div className="flex items-center">
          <h2 className="text-lg font-semibold text-gray-500 mr-2">{title}</h2>
          <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded-md">
            {tasks?.length || 0}
          </span>
        </div>

        {/* Add Task Button with Icon */}
        <button
          onClick={onAddTask}
          className="bg-slate-200 hover:bg-slate-300 text-slate-900 font-medium p-1 rounded flex items-center rounded-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
        </button>
      </div>

      <div className="space-y-2">
        {tasks?.map((task) => (
          <Task key={task.id} task={task} openModal={openModal} />
        ))}
      </div>
    </div>
  );
};

// Task Component
const Task = ({ task, openModal }) => {
  return (
    <div
      role="button"
      onClick={() => openModal(task)} // Trigger modal on click
      className="bg-white shadow rounded-lg p-4 flex justify-between items-center hover:bg-gray-50 border border-gray-100 cursor-pointer"
    >
      <div className="flex flex-col">
        <div className="flex items-center ms-2 font-semibold text-gray-900">
          <span className="mr-2">{task.title}</span>
          {task?.phase && (
            <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded">
              {task?.phase}
            </span>
          )}
        </div>
      </div>

      <div className="flex items-center space-x-4">
        {/* User Avatars */}
        {task?.users && (
          <div className="flex -space-x-3">
            {task.users.map((user) => (
              <div key={user.id}>
                <span className="sr-only">Open user menu</span>
                <span
                  className="size-6 rounded-full bg-blue-600 text-white flex items-center justify-center border border-white"
                  style={{ fontSize: "10px" }}
                >
                  {getInitials(user.username)}
                </span>
              </div>
            ))}
          </div>
        )}

        {/* Subtasks Progress */}
        {task?.subtasks?.length > 0 && (
          <span className="flex items-center text-gray-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            <span className="ml-1 text-sm">
              {`${
                task?.subtasks?.filter((subtask) => !!subtask?.checked).length
              } of ${task?.subtasks?.length}`}
            </span>
          </span>
        )}

        {/* Comments Count */}
        {task?.comments !== undefined && (
          <span className="flex items-center text-gray-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 0 1-.923 1.785A5.969 5.969 0 0 0 6 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337Z"
              />
            </svg>
            <span className="ml-1 text-sm">{task.comments}</span>
          </span>
        )}

        {/* Due Date */}
        {task?.dueDate && (
          <div className="text-sm text-gray-500">{task.dueDate}</div>
        )}
      </div>
    </div>
  );
};

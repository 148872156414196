import React, { useState } from "react";
import { format } from "date-fns";
import Badge from "../../../components/common/badges/Badge";
import Tabs from "../../../components/common/tabs/Tabs";
import {
  useDeleteExpense,
  useUpdateExpense,
} from "../../../hooks/useExpenses/useExpenses";
import ClientProjects from "../clients/details/ClientProjects";
import ExpenseProvider from "./details/ExpenseProvider";
import ExpenseItems from "./details/ExpenseItems";
import EditPurchaseOrder from "./details/EditPurchaseOrder";

const PurchaseOrder = ({ data, closeSidebar }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [expenseData, setExpenseData] = useState(data);
  const [error, setError] = useState({});

  const updateExpense = useUpdateExpense();
  const deleteExpense = useDeleteExpense();

  const handleEditToggle = () => {
    setShowOptions(false);
    setIsEditing(!isEditing);
  };

  const handleDelete = async () => {
    setShowOptions(false);
    if (window.confirm("Are you sure you want to delete this expense?")) {
      try {
        await deleteExpense.mutateAsync({ expenseId: expenseData.id });
        closeSidebar();
      } catch (err) {
        setError({ submit: "Failed to delete expense. Please try again." });
      }
    }
  };

  const handleExpenseUpdate = (updatedExpense) => {
    setExpenseData({
      ...expenseData,
      ...updatedExpense,
      project: data.project,
      provider: data.provider,
    });
    setIsEditing(false);
  };

  const formatCurrency = (value) =>
    new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
    })?.format(value);

  const tabs = [
    {
      label: "Items",
      content: (
        <ExpenseItems items={Object?.values(expenseData?.items || {})} />
      ),
    },
    {
      label: "Project",
      content: (
        <ClientProjects
          data={expenseData?.project ? [expenseData?.project] : []}
          title={"Expense Not Linked to Project."}
          description={
            "Please edit this expense or create a project to associate it."
          }
        />
      ),
    },
    {
      label: "Provider",
      content: <ExpenseProvider data={expenseData?.provider} />,
    },
  ];

  return (
    <div className="space-y-6 p-6">
      {error.submit && (
        <div className="p-4 mb-4 text-sm text-red-800 bg-red-50 rounded-lg">
          {error.submit}
        </div>
      )}
      {isEditing ? (
        <EditPurchaseOrder
          updateExpense={updateExpense}
          expenseData={expenseData}
          handleEditToggle={handleEditToggle}
          onExpenseUpdate={handleExpenseUpdate}
        />
      ) : (
        <div>
          <div className="space-y-2 mb-4">
            <div className="flex items-center justify-between mb-4">
              <div>
                <h5 className="text-xl font-bold leading-none text-gray-900">
                  {expenseData?.order_id || "Expense Details"}
                </h5>
                <Badge status={expenseData?.status} />
              </div>
              <div className="relative">
                <button
                  onClick={() => setShowOptions(!showOptions)}
                  className="p-1 rounded hover:bg-gray-200"
                >
                  {/* Dropdown Icon */}
                  <svg
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6.75a.75.75 0 0 1 0-1.5.75.75 0 0 1 0 1.5Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 12.75a.75.75 0 0 1 0-1.5.75.75 0 0 1 0 1.5Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 18.75a.75.75 0 0 1 0-1.5.75.75 0 0 1 0 1.5Z"
                    />
                  </svg>
                </button>
                {/* Dropdown Menu */}
                {showOptions && (
                  <div className="absolute right-0 mt-2 w-32 bg-white border rounded shadow-lg">
                    <button
                      onClick={handleEditToggle}
                      className="block w-full px-4 py-2 text-left text-sm hover:bg-gray-100"
                    >
                      Edit
                    </button>
                    <button
                      onClick={handleDelete}
                      className="block w-full px-4 py-2 text-left text-sm text-red-600 hover:bg-gray-100"
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="space-y-4">
              <div className="flex items-center space-x-2">
                <div>
                  <p className="text-sm text-gray-500">Description</p>
                  <p className="text-lg font-semibold text-gray-800">
                    {expenseData?.description || "---"}
                  </p>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <div>
                  <p className="text-sm text-gray-500">Amount</p>
                  <p className="text-lg font-semibold text-gray-800">
                    {formatCurrency(expenseData?.amount) || "---"}
                  </p>
                </div>
              </div>

              <div className="flex items-center space-x-2">
                <div>
                  <p className="text-sm text-gray-500">Due Date</p>
                  <p className="text-lg font-semibold text-gray-800">
                    {expenseData?.due_date
                      ? format(new Date(expenseData.due_date), "MMMM dd, yyyy")
                      : "---"}
                  </p>
                </div>
              </div>

              <div className="flex items-center space-x-2">
                <div>
                  <p className="text-sm text-gray-500">Payment Type</p>
                  <p className="text-lg font-semibold text-gray-800">
                    {expenseData?.payment_type || "---"}
                  </p>
                </div>
              </div>

              <div className="flex items-center space-x-2">
                <div>
                  <p className="text-sm text-gray-500">Location</p>
                  <p className="text-lg font-semibold text-gray-800">
                    {expenseData?.location || "---"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Tabs tabs={tabs} />
        </div>
      )}
    </div>
  );
};

export default PurchaseOrder;

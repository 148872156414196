import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeadings from "../../../components/common/pageHeadings/PageHeadings";
import DefaultCard from "../../../components/common/cards/DefaultCard";
import AsyncSelect from "react-select/async";
import { useCreateProject } from "../../../hooks/useProjects/useProjects";
import { useClients } from "../../../hooks/useClients/useClients";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

const CreateProject = () => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    status: "Planned",
    country: "",
    city: "",
    address: "",
    client_id: "", // Associate a client
  });

  const [error, setError] = useState({});
  const navigate = useNavigate();

  const {
    mutate: createProject,
    isLoading,
    isError,
    error: mutationError,
  } = useCreateProject();

  const { data: clientsData } = useClients(1, 10);

  const clientOptions =
    clientsData?.clients.map((client) => ({
      value: client.id,
      label: client.name,
    })) || [];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCountryChange = (country) => {
    setFormData((prevData) => ({ ...prevData, country, city: "" }));
  };

  const handleCityChange = (city) => {
    setFormData((prevData) => ({ ...prevData, city }));
  };

  const handleClientChange = (selectedClient) => {
    setFormData((prevData) => ({
      ...prevData,
      client_id: selectedClient ? selectedClient.value : "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.name) {
      setError({ name: "Project name is required." });
      return;
    }

    createProject(formData, {
      onSuccess: () => {
        navigate("/projects");
      },
      onError: (error) => {
        setError({ submit: error.message });
      },
    });
  };

  return (
    <div>
      <PageHeadings title="Create New Project" tags={[]} showCta={false} />
      <DefaultCard>
        <div className="p-4 bg-white rounded-md">
          {isError && mutationError && (
            <div
              className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50"
              role="alert"
            >
              <svg
                className="flex-shrink-0 inline w-4 h-4 mr-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span className="sr-only">Error</span>
              <div>{"Failed to create project"}</div>
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Project Name
              </label>
              <input
                type="text"
                name="name"
                placeholder="Project Name"
                value={formData.name}
                onChange={handleChange}
                required
                className="border p-2 w-full rounded max-w-md"
              />
              {error.name && (
                <div className="text-red-500 text-sm mt-1">{error.name}</div>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Project Status
              </label>
              <select
                name="status"
                value={formData.status}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
                required
              >
                <option value="Planned">Planned</option>
                <option value="In Progress">In Progress</option>
                <option value="Active">Active</option>
                <option value="Completed">Completed</option>
                <option value="Paused">Paused</option>
                <option value="Cancelled">Cancelled</option>
              </select>
              {error.status && (
                <div className="text-red-500 text-sm mt-1">{error.status}</div>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Project Description (optional)
              </label>
              <textarea
                name="description"
                placeholder="Project Description"
                value={formData.description}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
                rows="4"
              />
            </div>

            {/* Client Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Associate Client
              </label>
              <AsyncSelect
                cacheOptions
                defaultOptions={clientOptions}
                loadOptions={(inputValue, callback) =>
                  callback(
                    clientOptions.filter((option) =>
                      option.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    )
                  )
                }
                onChange={handleClientChange}
                placeholder="Select a client"
                className="max-w-md"
              />
            </div>

            {/* Address field */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Project Address (optional)
              </label>
              <input
                type="text"
                name="address"
                placeholder="Project Address"
                value={formData.address}
                onChange={handleChange}
                className="border p-2 w-full rounded max-w-md"
              />
            </div>

            {/* Country selection */}
            <div>
              <CountryDropdown
                value={formData.country}
                onChange={handleCountryChange}
                classes="border p-2 w-full rounded max-w-md"
              />
            </div>

            {/* City selection */}
            <div>
              <RegionDropdown
                country={formData.country}
                value={formData.city}
                onChange={handleCityChange}
                classes="border p-2 w-full rounded max-w-md"
              />
            </div>

            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded"
              disabled={isLoading}
            >
              {isLoading ? "Creating..." : "Create Project"}
            </button>
          </form>
        </div>
      </DefaultCard>
    </div>
  );
};

export default CreateProject;
